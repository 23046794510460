import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function SelectModalDropdown({
  options,
  title,
  value,
  onChange,
}) {
  return (
    <>
      <label>{title}</label>
      <Autocomplete
        disablePortal
        options={options}
        value={options.find((option) => option.value === value) || null}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.value : null);
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
}
