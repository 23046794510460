import React, { useState, useEffect } from "react";
import Tabs from "./tabs.tsx";
import { Heading } from "../../components/index.ts";
import TableSection from "./table-section.tsx";
import ModalSection from "./modal-section.tsx";
import RemoveModal from "./remove-modal.tsx";
import { useSelector } from "react-redux";
import { Col, Spin } from "antd";
import AffiliateTable from "./affiliate-table.tsx";
import { RootState } from "../../redux/store.ts";
import AffiliateModalSection from "./affiliate-modal-section.tsx";
import axiosClient from "../../utils/axios.ts";
import CollapseTable from "./collapse-table.tsx";
import MiniPartnerTable from "./mini-partner-table.tsx";

const Main = () => {
  const [itemIndex, setItemIndex] = useState<number>(0);
  const [isAdminOpen, setIsAdminOpen] = useState<boolean>(false);
  const [isEmployOpen, setIsEmployOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [isAffiliateEditOpen, setIsAffiliateEditOpen] =
    useState<boolean>(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState<any>(false);
  const [selectedValue, setSelectedValue] = useState();
  const [tableData, setTableData] = useState([]);
  const [employeeData, setEmployeeData] = useState();
  const [userType, setUserType] = useState("EMPLOYEE");
  const [invites, setInvites] = useState([]);
  // @ts-ignore
  const teams = useSelector((state: RootState) => state.teams);

  const isLoading = useSelector(
    (state: RootState) => state.isLoading.isLoading,
  );

  const getInvites = () => {
    axiosClient()
      .get("/invites")
      .then((res) => {
        setInvites(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getInvites();
  }, []);

  return (
    <div className="pb-20" style={{ paddingTop: "70px" }}>
      <Tabs itemIndex={itemIndex} setItemIndex={setItemIndex} />
      <div className="container mt-10 contained">
        <Heading
          heading={
            itemIndex === 0
              ? "Team Management"
              : itemIndex === 1
                ? "Partners Management"
                : ""
          }
          subHeading={
            itemIndex === 0
              ? "Manage your team members and their account permissions here."
              : itemIndex === 1
                ? "Manage your partners and their account permissions here."
                : ""
          }
        />

        {itemIndex === 0 && (
          <>
            {isLoading ? (
              <Spin fullscreen size="large" />
            ) : (
              <>
                <TableSection
                  tableData={[
                    ...teams?.admin,
                    ...invites?.filter((invite) => invite?.role === "ADMIN"),
                  ]}
                  label={
                    itemIndex === 0
                      ? "Admins"
                      : itemIndex === 1
                        ? "Partners"
                        : ""
                  }
                  btn="Invite Admins"
                  isAdmin
                  setIsAdminOpen={setIsAdminOpen}
                  setIsRemoveOpen={setIsRemoveOpen}
                  itemIndex={itemIndex}
                  btnIcon="https://ik.imagekit.io/8extk8sjo/Icon%20(23).svg?updatedAt=1709714532172"
                  setSelectedValue={setSelectedValue}
                  setEmployeeData={setEmployeeData}
                />
              </>
            )}
          </>
        )}

        {isLoading ? (
          <Spin fullscreen size="large" />
        ) : (
          <>
            {itemIndex === 0 ? (
              <>
                <div className="borderLines"></div>
                <TableSection
                  tableData={[
                    ...teams?.employee,
                    ...invites?.filter((invite) => invite?.role === "EMPLOYEE"),
                  ]}
                  label={"Employees"}
                  btn={"Invite Employees"}
                  btnIcon={
                    "https://ik.imagekit.io/8extk8sjo/Icon%20(25).svg?updatedAt=1709792043748"
                  }
                  isEmploy
                  setIsAdminOpen={setIsEmployOpen}
                  setIsEditOpen={setIsEditOpen}
                  setIsRemoveOpen={setIsRemoveOpen}
                  itemIndex={itemIndex}
                  setSelectedValue={setSelectedValue}
                  setEmployeeData={setEmployeeData}
                />
              </>
            ) : itemIndex === 1 ? (
              <>
                <AffiliateTable
                  tableData={[...teams?.affilliate]}
                  label={"Employees"}
                  btn={"Invite Employees"}
                  btnIcon={
                    "https://ik.imagekit.io/8extk8sjo/Icon%20(25).svg?updatedAt=1709792043748"
                  }
                  isEmploy
                  setIsAdminOpen={setIsEmployOpen}
                  setIsEditOpen={setIsAffiliateEditOpen}
                  setIsRemoveOpen={setIsRemoveOpen}
                  itemIndex={itemIndex}
                  userType={userType}
                  setUserType={setUserType}
                  setSelectedValue={setSelectedValue}
                  setEmployeeData={setEmployeeData}
                  setIsViewOpen={setIsViewOpen}
                />
              </>
            ) : itemIndex === 2 ? (
              <Col>
                <Col className="superPartner">
                  <h3>Super Partner Management</h3>
                  <p>
                    Manage your super partners and their account permissions
                    here.
                  </p>
                  <h6>Partners</h6>
                </Col>
                <CollapseTable />
              </Col>
            ) : (
              <>
                <Col>
                  <Col className="superPartner">
                    <h3>Mini Partner Management</h3>
                    <p>
                      Manage your super partners and their account permissions
                      here.
                    </p>
                    <h6>Partners</h6>
                  </Col>
                  <MiniPartnerTable />
                </Col>
              </>
            )}
          </>
        )}

        {isAdminOpen && (
          <ModalSection
            isAdminOpen={isAdminOpen}
            setIsAdminOpen={setIsAdminOpen}
            setUserType={setUserType}
            label="Invite Admins"
            refresh={getInvites}
          />
        )}
        {isEmployOpen && (
          <ModalSection
            isAdminOpen={isEmployOpen}
            setIsAdminOpen={setIsEmployOpen}
            setUserType={setUserType}
            label="Invite Employees"
            isEmploy
            refresh={getInvites}
          />
        )}
        {isEditOpen && (
          <ModalSection
            isAdminOpen={isEditOpen}
            setIsAdminOpen={setIsEditOpen}
            setUserType={setUserType}
            userType={userType}
            label="Edit Employee"
            isEdit
            employeeData={employeeData}
            selectedValue={selectedValue}
            refresh={getInvites}
          />
        )}
        <AffiliateModalSection
          isOpen={isAffiliateEditOpen}
          setIsOpen={setIsAffiliateEditOpen}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          isView={isViewOpen}
          setIsViewOpen={setIsViewOpen}
        />
        <RemoveModal
          // @ts-ignore
          isRemoveOpen={isRemoveOpen}
          setIsRemoveOpen={setIsRemoveOpen}
          tableData={tableData}
          selectedValue={selectedValue}
          setTableData={setTableData}
          employeeData={employeeData}
          refresh={getInvites}
        />
      </div>
    </div>
  );
};

export default Main;
