// // @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FilledButton from "../../../../components/filledButton.tsx";
import LabelInput from "../../../../components/commissionLabelInput.tsx";
import FilterIcon from "../../../../assets/images/filter.png";
import DateIcon from "../../../../assets/images/date.png";
import CloseIcon from "../../../../assets/images/close.png";
import SelectDropdown from "./component/select-dropdown/index.tsx";
import moment from "moment";
import BasicModal from "./component/transaction-modal/index.tsx";
import { Button, DatePicker, Input } from "antd";
import dayjs from "dayjs";

export default function FilterComponent({
  data,
  onFilterChange,
  isEmployee,
  isAffiliate,
  isMini,
  isSuper,
  refresh,
  handleItemClick,
}) {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [tempFilters, setTempFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ref = useRef(null);
  const { RangePicker } = DatePicker;

  const rangePresets = [
    { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
    {
      label: "Yesterday",
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    { label: "All Time", value: [dayjs("2021-12-31"), dayjs()] },
  ];

  const RangePickerFooter = ({ onOk, onCancel, selectedDates }) => {
    return (
      <div className="w-full py-4">
        <div className="flex justify-between mb-4">
          <Input
            value={
              selectedDates[0]
                ? moment(selectedDates[0]?.$d).format("DD/MM/YYYY")
                : ""
            }
            placeholder="Start Date"
            readOnly
            className="w-[45%]"
          />
          <Input
            value={
              selectedDates[1]
                ? moment(selectedDates[1]?.$d).format("DD/MM/YYYY")
                : ""
            }
            placeholder="End Date"
            readOnly
            className="w-[45%]"
          />
        </div>
        <div className="w-full flex justify-end gap-3 py-4 items-center">
          <Button
            style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
            className="rounded-[8px] h-[40px]"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
            className="bg-[#155EEF] rounded-[8px] h-[40px]"
            type="primary"
            onClick={onOk}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
    if (!event.target.closest(".ant-picker-dropdown")) {
      setDate(false);
      setDateRange([null, null]);
    }
  };

  const handleFilterChange = (filterName, value) => {
    setTempFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const applyFilters = () => {
    const newAppliedFilters = { ...tempFilters };
    // Preserve the dateRange if it exists
    if (appliedFilters.dateRange) {
      newAppliedFilters.dateRange = appliedFilters.dateRange;
    }
    setAppliedFilters(newAppliedFilters);
    onFilterChange(newAppliedFilters);
    setShow(false);
    updateOpenEvent(newAppliedFilters);
    // setAppliedFilters(tempFilters);
    // onFilterChange(tempFilters);
    // setShow(false);
    // updateOpenEvent(tempFilters);
  };

  const resetFilters = () => {
    // Reset tempFilters but keep the dateRange if it exists
    const resetFilters = appliedFilters.dateRange
      ? { dateRange: appliedFilters.dateRange }
      : {};
    setTempFilters(resetFilters);
    // setTempFilters({});
  };

  const removeFilter = (key) => {
    const newAppliedFilters = { ...appliedFilters };
    delete newAppliedFilters[key];
    setAppliedFilters(newAppliedFilters);
    setTempFilters(newAppliedFilters);
    onFilterChange(newAppliedFilters);
    updateOpenEvent(newAppliedFilters);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const applyDateRange = () => {
    if (dateRange[0] && dateRange[1]) {
      const newFilters = {
        ...appliedFilters,
        dateRange: { startDate: dateRange[0], endDate: dateRange[1] },
      };
      setAppliedFilters(newFilters);
      setTempFilters(newFilters);
      onFilterChange(newFilters);
      setDate(false);
      updateOpenEvent(newFilters);
    }
  };

  // const resetDateRange = () => {
  //   setDateRange([null, null]);
  //   const newFilters = { ...appliedFilters };
  //   delete newFilters.dateRange;
  //   setAppliedFilters(newFilters);
  //   setTempFilters(newFilters);
  //   onFilterChange(newFilters);
  //   updateOpenEvent(newFilters);
  // };

  const updateOpenEvent = (filters) => {
    const filteredEvents = data.filter((event) => {
      return (
        (!filters.minAmount ||
          parseFloat(event.fee) >= parseFloat(filters.minAmount)) &&
        (!filters.maxAmount ||
          parseFloat(event.fee) <= parseFloat(filters.maxAmount)) &&
        (!filters.companyName ||
          event.organizationName === filters.companyName) &&
        (!filters.affiliate || event.employeeName === filters.affiliate) &&
        (!filters.revenueType || event.feeType === filters.revenueType) &&
        (!filters.dateRange ||
          (moment(event.date).isSameOrAfter(filters.dateRange.startDate) &&
            moment(event.date).isSameOrBefore(filters.dateRange.endDate)))
      );
    });

    if (filteredEvents.length > 0) {
      handleItemClick(filteredEvents[0]);
    }
  };

  const capitalize = (str) =>
    str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  const handleFilterButtonClick = () => {
    setTempFilters({ ...appliedFilters });
    setShow(true);
    setDate(false);
    setDateRange([null, null]);
  };

  const companyOptions = [
    ...new Set(data?.map((event) => event.organizationName)),
  ].map((name) => ({ value: name, label: name }));
  const affiliateOptions = [
    ...new Set(data?.map((event) => event.affiliateName)),
  ].map((name) => ({ value: name, label: name }));
  const employeeOptions = [
    ...new Set(data?.map((event) => event.employeeName)),
  ].map((name) => ({ value: name, label: name }));
  const superOptions = [
    ...new Set(data?.map((event) => event.superPartnerName)),
  ].map((name) => ({ value: name, label: name }));
  const miniOptions = [...new Set(data?.map((event) => event.partnerName))].map(
    (name) => ({ value: name, label: name }),
  );
  const revenueTypeOptions = [
    ...new Set(data?.map((event) => event.feeType)),
  ].map((type) => ({ value: type, label: capitalize(type) }));

  return (
    <>
      <Box className="filterBox" style={{ position: "relative" }}>
        <Box className="filterOption">
          <FilledButton className="btnDate" onClick={handleFilterButtonClick}>
            <img src={FilterIcon} alt="" /> Filters
          </FilledButton>
          {show && (
            <Box className="filterSection" ref={ref}>
              <Typography variant="h1">Filters</Typography>
              <Box className="filterMenu">
                <Grid
                  container
                  sx={{ width: "352px !important", margin: "0 auto" }}
                >
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Min amount"
                        type="number"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={tempFilters.minAmount || ""}
                        onChange={(e) =>
                          handleFilterChange("minAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={0.6}></Grid>
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Max amount"
                        type="number"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={tempFilters.maxAmount || ""}
                        onChange={(e) =>
                          handleFilterChange("maxAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Company name"
                        options={companyOptions}
                        value={tempFilters.companyName || ""}
                        onChange={(value) =>
                          handleFilterChange("companyName", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title={
                          isAffiliate
                            ? "Partners"
                            : isMini
                              ? "Mini Partners"
                              : isSuper
                                ? "Super Partners"
                                : "Employees"
                        }
                        options={
                          isAffiliate
                            ? affiliateOptions
                            : isMini
                              ? miniOptions
                              : isSuper
                                ? superOptions
                                : employeeOptions
                        }
                        value={tempFilters.affiliate || ""}
                        onChange={(value) =>
                          handleFilterChange("affiliate", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Revenue Type"
                        options={revenueTypeOptions}
                        value={tempFilters.revenueType || ""}
                        onChange={(value) =>
                          handleFilterChange("revenueType", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                      width: "100%",
                    }}
                  >
                    <FilledButton className="cancelBtn" onClick={resetFilters}>
                      Reset
                    </FilledButton>
                    <FilledButton
                      style={{ background: "#155EEF", color: "#fff" }}
                      className="cancelBtn"
                      onClick={applyFilters}
                    >
                      Apply
                    </FilledButton>
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="filterOption" sx={{ position: "relative" }}>
          {!date && (
            <FilledButton
              className="btnDate sz"
              onClick={() => {
                setDate(true);
                setShow(false);
                // Set dateRange to the current applied date range if it exists
                if (appliedFilters.dateRange) {
                  setDateRange([
                    appliedFilters.dateRange.startDate,
                    appliedFilters.dateRange.endDate,
                  ]);
                }
              }}
            >
              <img src={DateIcon} alt="" /> Select Dates
            </FilledButton>
          )}
          {date && (
            <Box className="">
              <RangePicker
                open={date}
                popupClassName="custom-calendar-class yearPicker"
                presets={rangePresets}
                minDate={dayjs(new Date(2022, 0, 1))}
                maxDate={dayjs(new Date())}
                onChange={handleDateRangeChange}
                value={dateRange}
                className="border border-[#D0D5DD] h-[40px]"
                renderExtraFooter={() => (
                  <RangePickerFooter
                    onOk={applyDateRange}
                    // onCancel={() => {
                    //   setDate(false);
                    //   setDateRange([null, null]);
                    // }}
                    onCancel={() => {
                      setDate(false);
                      setDateRange(
                        appliedFilters.dateRange
                          ? [
                              appliedFilters.dateRange.startDate,
                              appliedFilters.dateRange.endDate,
                            ]
                          : [null, null],
                      );
                    }}
                    selectedDates={dateRange}
                  />
                )}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {Object.entries(appliedFilters).map(([key, value]) => (
            <FilledButton
              key={key}
              className="tagBtn"
              onClick={() => removeFilter(key)}
            >
              <img src={CloseIcon} alt="" />
              {key === "companyName" && `Company name: ${value}`}
              {key === "revenueType" && `Revenue Type: ${capitalize(value)}`}
              {key === "affiliate" && `User Name: ${value}`}
              {key === "dateRange" &&
                `Date: ${moment(value.startDate?.$d).format("DD/MM/YYYY")} - ${moment(value.endDate?.$d).format("DD/MM/YYYY")}`}
              {key === "minAmount" && `Min amount: ${value}`}
              {key === "maxAmount" && `Max amount: ${value}`}
            </FilledButton>
          ))}
        </Box>
        {(isAffiliate || isEmployee) && (
          <FilledButton className="addBtn-1" onClick={handleOpen}>
            + Manually Add Transaction
          </FilledButton>
        )}
      </Box>
      <BasicModal
        open={open}
        handleClose={handleClose}
        refresh={refresh}
        isAffiliate={isAffiliate}
      />
    </>
  );
}
