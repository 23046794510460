import React from "react";
import { Drawer, Spin, Typography } from "antd";
import moment from "moment";

type initialState = {
  isViewOpen: any;
  setIsViewOpen: any;
  isCompany: any;
  data?: any;
  setViewData?: any;
};

const Sidebar = ({
  isViewOpen,
  setIsViewOpen,
  isCompany,
  data,
  setViewData,
}: initialState) => {
  const { Text } = Typography;

  const getStatusStyle = (status) => {
    switch (status) {
      case "Churned":
      case "Disqualified":
        return { backgroundColor: "#FEF3F2", color: "#B42318" };
      case "Lead":
        return { backgroundColor: "#EFF4FF", color: "#0439a3" };
      case "In Pipeline":
        return { backgroundColor: "#FFFAEB", color: "#B54708" };
      case "Customer":
        return { backgroundColor: "#ECFDF3", color: "#027A48" };
      default:
        return { backgroundColor: "#FFFAEB", color: "#B54708" };
    }
  };

  const status = data?.Status || data?.pipedrive_org_id?.Status;
  const statusStyle = getStatusStyle(status);
  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => {
          setIsViewOpen(false);
          setViewData();
        }}
        visible={isViewOpen}
        bodyStyle={{ padding: "20px" }}
        className="custom-drawer"
      >
        {data ? (
          <>
            <div className="w-full h-full ">
              <div className="flex justify-between items-center">
                <Text className="text-[#101828] text-[20px] font-semibold">
                  {isCompany ? " Company Details" : "Overview"}
                </Text>

                <img
                  src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
                  alt=""
                  className="w-[15px] cursor-pointer"
                  onClick={() => {
                    setIsViewOpen(false);
                    setViewData();
                  }}
                />
              </div>

              {!isCompany && (
                <div className="flex items-center gap-3 mt-6">
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/building-05.svg?updatedAt=1709552570646"
                    alt=""
                    className="w-[20px]"
                  />
                  <Text className="text-[#98A2B3] text-[14px] font-medium">
                    Company Details
                  </Text>
                </div>
              )}

              <div className="flex flex-wrap items-center gap-y-3 mt-4 pb-4 border-b">
                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Name:
                  </Text>
                  <Text className="text-[#475467] text-[14px] font-normal">
                    {data?.Name
                      ? data?.Name
                      : data?.pipedrive_org_id?.Name
                        ? data?.pipedrive_org_id?.Name
                        : "---"}
                  </Text>
                </div>

                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Domain:
                  </Text>
                  <Text className="text-[#475467] text-[14px] font-normal">
                    {data?.Website
                      ? data?.Website
                      : data?.pipedrive_org_id?.Website
                        ? data?.pipedrive_org_id?.Website
                        : "No domain"}
                  </Text>
                </div>

                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Refferred On:
                  </Text>
                  <Text className="text-[#475467] text-[14px] font-normal">
                    {data?.add_time
                      ? moment(data?.add_time).format("DD/MM/YYYY")
                      : data?.pipedrive_org_id?.add_time
                        ? moment(data?.pipedrive_org_id?.add_time).format(
                            "DD/MM/YYYY",
                          )
                        : "0/0/0000"}
                  </Text>
                </div>

                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Status:
                  </Text>

                  <div className="mt-1">
                    <Text
                      style={statusStyle}
                      className=" text-[14px] font-medium   w-auto rounded-[16px] p-1 px-3"
                    >
                      {data?.Status
                        ? data?.Status
                        : data?.pipedrive_org_id?.Status
                          ? data?.pipedrive_org_id?.Status
                          : "No status"}
                    </Text>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex items-center gap-3 mt-6">
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/Icon%20(20).svg?updatedAt=1709554649235"
                    alt=""
                    className="w-[20px]"
                  />
                  <Text className="text-[#98A2B3] text-[14px] font-medium">
                    Contact Details
                  </Text>
                </div>

                <div className="flex flex-wrap items-center gap-y-3 pb-6 border-b border-[#EAECF0]">
                  <div className="grid w-[50%]">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Name:
                    </Text>
                    <Text className="text-[#475467] text-[14px] font-normal">
                      {data?.person?.name || "Dummy Name"}
                    </Text>
                  </div>
                  <div className="grid w-[50%]">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Email:
                    </Text>
                    <Text className="text-[#475467] text-[14px] font-normal">
                      {data?.person?.email || "dummy@email.com"}
                    </Text>
                  </div>
                </div>

                {isCompany && (
                  <>
                    <div className="pb-4 border-b">
                      <div className="flex items-center gap-3 mt-6">
                        <img
                          src="https://ik.imagekit.io/8extk8sjo/Icon%20(20).svg?updatedAt=1709554649235"
                          alt=""
                          className="w-[20px]"
                        />
                        <Text className="text-[#98A2B3] text-[14px] font-medium">
                          Closed by
                        </Text>
                      </div>

                      <div className="grid full mt-3">
                        <Text className="text-[#344054] text-sm font-medium">
                          Name:
                        </Text>
                        <div className="flex justify-between items-center">
                          <Text className="text-[#475467] text-sm font-normal">
                            {data?.Owner?.name ? data?.Owner?.name : "---"}
                          </Text>
                        </div>
                      </div>
                    </div>

                    <div className="pb-4 border-b">
                      <div className="flex items-center gap-3 mt-6">
                        <img
                          src="https://ik.imagekit.io/8extk8sjo/Icon%20(20).svg?updatedAt=1709554649235"
                          alt=""
                          className="w-[20px]"
                        />
                        <Text className="text-[#98A2B3] text-[14px] font-medium">
                          Referred by
                        </Text>
                      </div>

                      <div className="grid full mt-3">
                        <Text className="text-[#344054] text-sm font-medium">
                          Name:
                        </Text>
                        <div className="flex justify-between items-center">
                          <Text className="text-[#475467] text-sm font-normal">
                            {data?.AffiliateName ||
                              data?.SDROwner?.name ||
                              "---"}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="flex items-center gap-3 mt-6">
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/Icon%20(21).svg?updatedAt=1709554813294"
                    alt=""
                    className="w-[20px]"
                  />
                  <Text className="text-[#98A2B3] text-[14px] font-medium">
                    Payout
                  </Text>
                </div>

                {isCompany ? (
                  <>
                    <div className="flex flex-wrap items-center gap-y-3 mt-4 pb-6">
                      <div className="grid w-[50%]">
                        <Text className="text-[#344054] text-[14px] font-medium">
                          Total commission paid:
                        </Text>
                        <Text className="text-[#475467] text-[14px] font-normal">
                          {data?.CommissionPaidToOwner
                            ? `$${data?.CommissionPaidToOwner}`
                            : "---"}
                        </Text>
                      </div>

                      <div className="grid w-[50%]">
                        <Text className="text-[#344054] text-[14px] font-medium">
                          Total revenue:
                        </Text>
                        <Text className="text-[#475467] text-[14px] font-normal">
                          {data?.AggregateRevenue
                            ? `$${data?.AggregateRevenue}`
                            : "---"}
                        </Text>
                      </div>
                      <div className="grid w-[50%]"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-wrap items-center gap-y-3 mt-4 pb-4">
                      <div className="grid w-[50%]">
                        <Text className="text-[#344054] text-[14px] font-medium">
                          Paid amount:
                        </Text>
                        <Text className="text-[#475467] text-[14px] font-normal">
                          ${data?.commission_paid ? data?.commission_paid : "0"}
                        </Text>
                      </div>

                      <div className="grid w-[50%]">
                        <Text className="text-[#344054] text-[14px] font-medium">
                          Unpaid amount:
                        </Text>
                        <Text className="text-[#475467] text-[14px] font-normal">
                          $
                          {data?.commission
                            ? data?.commission - data?.commission_paid
                            : "0"}
                        </Text>
                      </div>

                      <div className="grid w-[50%]">
                        <Text className="text-[#344054] text-[14px] font-medium">
                          Total earnings:
                        </Text>
                        <Text className="text-[#475467] text-[14px] font-normal">
                          ${data?.commission ? data?.commission : "0"}
                        </Text>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center items-center h-[100vh] w-full">
              <Spin />
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default Sidebar;
