import React from "react";
import Header from "../../layout/header/index.tsx";
import Main from "../../views/payouts/main.tsx";
const Payouts = () => {
  return (
    <>
      <Header />
      <div
        className="container mt-10 contained listedBoxing"
        style={{ paddingTop: "70px" }}
      >
        <h3 className="headPayment">Payments</h3>
        <Main />
      </div>
    </>
  );
};

export default Payouts;
