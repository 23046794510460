export const RECENT_ACTIVITY = [
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar.png?updatedAt=1709212217258",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(6).svg?updatedAt=1710409677128",
    heading: "Aliah Lane",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(5).svg?updatedAt=1710409677426",
    heading: "Lana Steiner",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(4).svg?updatedAt=1710409663804",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar.png?updatedAt=1709212217258",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Aliah Lane",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Lana Steiner",
    name: "Adam Jones",
    date: "08/09/23",
  },
];

export const ANCHOR = [
  {
    label: "Approvals",
  },
  {
    label: "Payments",
  },
];

export const FILTER_COMPANY = [
  {
    label: "Catalog",
  },
  {
    label: "Capsule",
  },
  {
    label: "Command+R",
  },
  {
    label: "Hourglass",
  },
  {
    label: "Layers",
  },
  {
    label: "Quotient",
  },
  {
    label: "Sisyphus",
  },
];

export const FILTER_NAME = [
  {
    label: "Brandon Workman",
  },
  {
    label: "Lindsey Vetrovs",
  },
  {
    label: "Mira Gouse",
  },
  {
    label: "Haylie George",
  },
];

export const FILTER_TYPE = [
  {
    Name: "Partner",
    Value: "AFFILIATES",
  },
  {
    Name: "Super Partner",
    Value: "SUPER_PARTNER",
  },
  {
    Name: "Mini Partner",
    Value: "MINI_PARTNER",
  },
  {
    Name: "Employee",
    Value: "EMPLOYEE",
  },
];
export const FILTER_TYPE2 = [
  {
    label: "Keira",
  },
  {
    label: "Mira",
  },
  {
    label: "wilson",
  },
];

export const FILTER_STATUS = [
  {
    Name: "Unpaid",
    bgColor: "#FFFAEB",
    color: "#B54708",
  },
  {
    Name: "Paid Automated",
    bgColor: "#ECFDF3",
    color: "#027A48",
  },
  {
    Name: "Paid Manual",
    bgColor: "#ECFDF3",
    color: "#027A48",
  },
  {
    Name: "Decline",
    bgColor: "#FEF3F2",
    color: "#B42318",
  },
];

export const FILTER_STATUS1 = [
  {
    Name: "Unpaid",
    Value: "Unpaid",
    bgColor: "#FFFAEB",
    color: "#B54708",
  },
  {
    Name: "Paid",
    Value: "Paid",
    bgColor: "#ECFDF3",
    color: "#027A48",
  },
];

export const FILTER_COMPANY_STATUS = [
  {
    Name: "Churned",
    bgColor: "#FEF3F2",
    color: "#B42318",
  },
  {
    Name: "Lead",
    bgColor: "#EFF4FF",
    color: "#0439a3",
  },
  {
    Name: "In Pipeline",
    bgColor: "#FFFAEB",
    color: "#B54708",
  },
  {
    Name: "Disqualified",
    bgColor: "#FEF3F2",
    color: "#B42318",
  },

  {
    Name: "Customer",
    bgColor: "#ECFDF3",
    color: "#027A48",
  },
];

export const TEAM_TABS = [
  {
    label: "Team",
    isCount: false,
  },
  {
    label: "Partners",
    isCount: true,
  },
  {
    label: "Super Partners",
    isCount: true,
  },
  {
    label: "Mini Partners",
    isCount: true,
  },
];

export const SETTINGS_TABS = [
  {
    label: "Profile",
  },
  {
    label: "Password",
  },
  // {
  //   label: "Integration",
  // },
  {
    label: "Commissions",
  },
  {
    label: "Error Logs",
  },
];

export const SETTINGS_INTEGRATION = [
  {
    label: "Pipedrive",
    icon: "/images/pipedrive.svg",
    description: "Pull in leads, accounts and revenue data.",
    btntext: "Connect",
    isEnable: false,
  },
  // {
  //   label: "Mercury",
  //   icon: "https://ik.imagekit.io/8extk8sjo/mercury-logo-icon%202.svg?updatedAt=1709881615323",
  //   description: "Automate your payments using your mercury account.",
  //   btntext: "View Integration",
  //   isEnable: true,
  // },
];

export const PAYOUTS_DATA_COLUMN = [
  {
    title: "Name",
    dataIndex: "name",
    key: "column1",
  },
  {
    title: "Months",
    dataIndex: "months",
    key: "column2",
  },
  {
    title: "Type",
    dataIndex: "Type",
    key: "column3",
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "column4",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "column5",
  },
  {
    title: "",
    dataIndex: "edit",
    key: "column6",
  },
];

export const PAYOUT_DATA = [
  {
    id: "1",
    name: "Brandon Workman",
    Type: "Affiliate",
    Amount: "$800",
    Company: "Mira gouse",
    Date: "24-08-10",
    Status: "Unpaid",
    edit: "",
  },
  {
    id: "2",
    name: "Ronaldo",
    Type: "Affiliate",
    Amount: "$12000",
    Company: "Mira gouse",
    Date: "24-05-11",
    Status: "Paid Automated",
    edit: "",
  },
  {
    id: "3",
    name: "Messi",
    Type: "Employee",
    Amount: "$2000",
    Company: "Mira gouse",
    Date: "24-07-20",
    Status: "Paid Manual",
    edit: "",
  },
  {
    id: "4",
    name: "Mape",
    Type: "Affiliate",
    Amount: "$200",
    Company: "Mira gouse",
    Date: "24-09-11",
    Status: "Declined",
    edit: "",
  },
  {
    id: "2",
    name: "Ronaldo",
    Type: "Affiliate",
    Amount: "$12000",
    Company: "Mira gouse",
    Date: "24-05-11",
    Status: "Paid Automated",
    edit: "",
  },
  {
    id: "2",
    name: "Ronaldo",
    Type: "Affiliate",
    Amount: "$12000",
    Company: "Mira gouse",
    Date: "24-05-11",
    Status: "Paid Automated",
    edit: "",
  },
  {
    id: "3",
    name: "Messi",
    Type: "Employee",
    Amount: "$2000",
    Company: "Mira gouse",
    Date: "24-07-20",
    Status: "Paid Manual",
    edit: "",
  },
];

export const COMPANIES_DATA_COLUMN = [
  {
    title: "Company",
    dataIndex: "name",
    key: "column1",
  },
  {
    title: "Referred By",
    dataIndex: "referred",
    key: "column2",
  },
  {
    title: "Closed By",
    dataIndex: "closedBy",
    key: "column3",
  },
  {
    title: "Created On",
    dataIndex: "createdOn",
    key: "column4",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "column5",
  },

  {
    title: "Commission Paid",
    dataIndex: "commission",
    key: "column6",
  },
  {
    title: "Total Revenue",
    dataIndex: "total",
    key: "column7",
  },

  {
    title: "",
    dataIndex: "edit",
    key: "column8",
  },
];

export const COMPANIES_DATA = [
  {
    id: "1",
    name: "Spotify",
    referred: "Kierra",
    commission: "$1000",
    createdOn: "31/07/23",
    closeDate: "Kierra",
    status: "Converted",
    total: "$4000",
    edit: "",
  },
  {
    id: "2",
    name: "Layers",
    referred: "Mira",
    commission: "$2000",
    createdOn: "31/07/23",
    closeDate: "Kierra",
    status: "Converted",
    total: "$2000",

    edit: "",
  },
  {
    id: "3",
    name: "Quotient",
    referred: "Omar",
    commission: "$9000",
    createdOn: "31/07/23",
    closeDate: "Kierra",
    status: "Converted",
    edit: "",
    total: "$1000",
  },
  {
    id: "4",
    name: "Sisyphus",
    referred: "Wilson",
    commission: "$8090",
    createdOn: "31/07/23",
    closeDate: "Wilson",
    status: "Converted",
    edit: "",
    total: "$4000",
  },
  {
    id: "4",
    name: "Sisyphus",
    referred: "Wilson",
    commission: "$1000",
    createdOn: "31/07/23",
    closeDate: "Kierra",
    status: "Declined",
    edit: "",
    total: "$4000",
  },
  {
    id: "4",
    name: "Sisyphus",
    referred: "Wilson",
    commission: "$100",
    createdOn: "31/07/23",
    closeDate: "	Omar",
    status: "Converted",
    edit: "",
    total: "$360",
  },
  {
    id: "4",
    name: "Sisyphus",
    referred: "Wilson",
    commission: "$400",
    createdOn: "31/07/23",
    closeDate: "Kierra",
    status: "Converted",
    edit: "",
    total: "$4000",
  },
];

export const TEAMS_ADMIN_DATA = [
  {
    id: "1",
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(4).svg?updatedAt=1710409565037",
    name: "Olivia Rhye",
    email: "email@company.com",
    role: "Admin",
  },
  {
    id: "2",
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(5).svg?updatedAt=1710409677426",
    name: "Messi",
    email: "messi@company.com",
    role: "Owner",
  },
  {
    id: "3",
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(6).svg?updatedAt=1710409677128",
    name: "Rhye",
    email: "yoho@company.com",
    role: "Admin",
  },
  {
    id: "4",
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(6).svg?updatedAt=1710409677128",
    name: "Oliv",
    email: "oliv@company.com",
    role: "Admin",
  },
];

export const TEAMS_EMPLOYEE_DATA = [
  {
    id: "1",
    icon: "",
    name: "Drink",
    email: "email@company.com",
    Commission: "Flat 20% for Employees ",
  },
  {
    id: "2",

    icon: "",
    name: "Messi",
    email: "messi@company.com",
    Commission: "Flat 20% for Employees ",
  },
  {
    id: "3",

    icon: "",
    name: "Rhye",
    email: "yoho@company.com",
    Commission: "Flat 20% for Employees ",
  },
  {
    id: "4",

    icon: "",

    name: "Oliv",
    email: "oliv@company.com",
    Commission: "Flat 20% for Employees ",
  },
];

export const DASHBOARD_BTNS = [
  {
    label: "1 Month",
    className:
      "h-[44px] rounded-l-[6px] font-sans rounded-r-none  shadow-none p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: {
      shadow: "0px 1px 2px 0px #1018280D",
      border: "1.5px solid #D0D5DD",
    },
  },
  {
    label: "3 Months",
    className:
      "h-[44px] rounded-none font-sans shadow-none  p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: {
      shadow: "0px 1px 2px 0px #1018280D",
      borderBottom: "1.5px solid #D0D5DD",
      borderTop: "1.5px solid #D0D5DD",
    },
  },
  {
    label: " All Time",
    className:
      "h-[44px] rounded-r-[6px] font-sans rounded-l-none shadow-none p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: {
      shadow: "0px 1.5px 2px 0px #1018280D",
      border: "1.5px solid #D0D5DD",
    },
  },
];
