import React from "react";
import { Row, Col, Button, Dropdown } from "antd";
import { Logo } from "../../components/index.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context.tsx";
import { RootState } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleNavigate = (item: any, index: number) => {
    navigate(item.url);
  };
  // @ts-ignore
  const teams = useSelector((state: RootState) => state.teams);
  const payouts = useSelector((state: RootState) => state.payouts.payouts);

  const { user, signOut } = useAuthContext();

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => navigate("/settings")}
          className="w-full border-b border-[#EAECF0] font-sans gap-3 p-3 flex "
        >
          <div className="w-[44px] h-[44px] rounded-[50px] overflow-hidden bg-gray-100 cursor-pointer">
            <img
              src={
                user?.profile_image
                  ? user?.profile_image
                  : "/images/profile.svg"
              }
              alt=""
              className="object-cover w-full h-full"
            />
          </div>
          <div className="grid">
            <span className="text-[#344054] text-[14px] font-semibold capitalize">
              {user?.name}
            </span>
            <span className="-mt-1">{user?.email}</span>
          </div>
        </div>
      ),
    },

    {
      key: "4",
      label: (
        <div className="p-3 py-4">
          <div
            onClick={() => {
              navigate("/");
              signOut();
            }}
            className="flex items-center justify-start  w-full rounded-md  text-white font-sans gap-3"
          >
            <img
              src="https://ik.imagekit.io/8extk8sjo/log-out-01.svg?updatedAt=1710827754232"
              alt=""
            />
            <span className="text-[#344054] font-medium text-sm">Log out</span>
          </div>
        </div>
      ),
    },
  ];

  const NAV_ITEMS = [
    {
      label: "Dashboard",
      isCount: false,
      counts: 0,
      url: "/dashboard",
    },
    {
      label: "Comissions",
      isCount: false,
      url: "/commision",
    },
    {
      label: "Payments",
      isCount: true,
      counts: payouts?.length,
      url: "/payment",
    },
    {
      label: "Team Management",
      isCount: true,
      counts: teams?.affilliate?.length,
      url: "/team-management",
    },
    {
      label: "Companies",
      isCount: false,
      counts: 0,
      url: "/companies",
    },
    {
      label: "Settings",
      isCount: false,
      counts: 0,
      url: "/settings",
    },
  ];

  return (
    <div
      className="w-full h-[72px] flex justify-center bg-white border-b border-[#EAECF0] font-sans"
      style={{ position: "fixed", top: "0", zIndex: "99" }}
    >
      <div className="container h-[72px] bg-[#FFFFFF] border-b border-[#EAECF0] fixed z-50 ">
        <Row>
          <Col span={3} className="flex items-center justify-start h-[72px] ">
            <Logo />
          </Col>
          <Col span={17} className="h-[72px] flex items-center gap-1 pl-2">
            <Col style={{ width: "795px", display: "flex" }}>
              {NAV_ITEMS?.map((item: any, index: number) => (
                <Button
                  key={index}
                  onClick={() => handleNavigate(item, index)}
                  className={`h-[44px] shadow-none border-none  font-sans p-[8px, 12px, 8px, 12px] rounded-[6px] font-semibold text-[16px] text-[#344054] flex items-center gap-2  ${
                    pathname === item.url && "bg-[#EFF4FF] pathActive"
                  }`}
                >
                  {item?.label}
                  {item?.isCount && (
                    <div className="rounded-[16px] bg-[#F2F4F7] px-3 font-sans activeRound">
                      {item?.counts}
                    </div>
                  )}
                </Button>
              ))}
            </Col>
          </Col>
          <Col span={4} className="flex items-center justify-end gap-6">
            <div className="w-[44px] h-[44px] rounded-[50px] overflow-hidden bg-gray-100 cursor-pointer">
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                className="border-b border-[#EAECF0] shadow-none p-0"
              >
                <img
                  src={
                    user?.profile_image
                      ? user?.profile_image
                      : "/images/profile.svg"
                  }
                  alt=""
                  className="object-cover w-full h-full"
                />
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
