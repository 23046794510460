import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart: React.FC<{ stats: any; data: any }> = ({ stats, data }) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontFamily: "Inter, sans-serif", // Change this to your preferred font
          fontSize: "12px", // Change this to your preferred font size
          colors: "#475467", // Change this to your preferred color
          fontWeight: "400",
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      tickAmount: 8, // Adjust this to increase/decrease space between lines
    },
    grid: {
      borderColor: "#F2F4F7", // Set grid line color
      strokeDashArray: 0, // Set to 0 for solid lines
      opacity: 0.5, // Reduce opacity for the grid lines
    },
    colors: ["#15b79e", "#155eef"],
    legend: {
      position: "top",
    },
    fill: {
      gradient: {
        shadeIntensity: 0.1,
        type: "vertical",
        shade: "light",
        opacityFrom: 0.3,
        opacityTo: 0.05,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "#007bff",
            opacity: 0.3,
          },
          {
            offset: 5,
            color: "#007bff",
            opacity: 0.05,
          },
        ],
      },
    },
  };

  const series = [
    {
      name: "Referred",
      data: data?.map((item) => item.referred),
    },
    {
      name: "Closed",
      data: data?.map((item) => item.converted),
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        className="lineChart"
        options={options}
        series={series}
        type="area"
        height={400}
      />
    </div>
  );
};

export default LineChart;
