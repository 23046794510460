import React from "react";
import { Checkbox } from "antd";

const App: React.FC<{
  data:
    | { name?: string; Name?: string; bgColor?: string; color?: string }
    | string;
  checked?: boolean;
  onChange: (name: string, checked: boolean) => void;
}> = ({ data, onChange, checked }) => {
  const displayName =
    typeof data === "string"
      ? data
      : data?.Name
        ? data?.Name
        : data?.name
          ? data?.name
          : "No user coming";

  return (
    <Checkbox
      onChange={(e) => onChange(displayName, e.target.checked)}
      checked={checked}
    >
      <span
        style={{
          backgroundColor: typeof data === "object" ? data.bgColor : undefined,
          color: typeof data === "object" ? data.color : undefined,
          ...(typeof data === "object" &&
            data.bgColor && {
              padding: "5px",
              borderRadius: "12px",
            }),
        }}
        className="text-[#344054] text-[14px] font-medium capitalize"
      >
        {displayName}
      </span>
    </Checkbox>
  );
};

export default App;
