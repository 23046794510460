// @ts-nocheck
import React, { useState } from "react";
import { Drawer, Typography, Input, Button, Spin } from "antd";
import { Slider } from "../../components/index.ts";
import {
  FILTER_NAME,
  FILTER_TYPE,
  FILTER_STATUS1,
} from "../../constants/data.ts";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";
import Checkbox from "./checkbox.tsx";
const SidebarFilter = ({
  isViewOpen,
  setIsViewOpen,
  setPayoutData,
  sidebarData,
  setCheckboxValues,
  checkboxValues,
  payouts,
  tempCheckboxValues,
  setTempCheckboxValues,
}) => {
  const { Text } = Typography;

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const [itemsToShow, setItemsToShow] = useState(10);
  const [searchName, setSearchName] = useState([...FILTER_NAME]);
  const [searchValue, setSearchValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState([0, 10000]);

  useState(() => {
    setSliderValue([0, 10000]);
  }, [payouts]);

  const handleCheckboxChange = (name, value) => {
    if (name === "Commission") {
      setSliderValue(value);
    }
    setTempCheckboxValues((prevValues) => {
      if (name === "Commission") {
        // For Commission, we always want to replace the existing value
        return {
          ...prevValues,
          [name]: value,
        };
      }

      const currentValues = prevValues[name] || [];
      if (currentValues.includes(value)) {
        // Remove value if it is already present
        return {
          ...prevValues,
          [name]: currentValues.filter((v) => v !== value),
        };
      } else {
        // Add value if it is not present
        return {
          ...prevValues,
          [name]: [...currentValues, value],
        };
      }
    });
  };

  const handleShowMore = () => {
    setItemsToShow((prevItemsToShow) => prevItemsToShow + 10);
  };

  const handleShowLess = () => {
    setItemsToShow((prevItemsToShow) => Math.max(10, prevItemsToShow - 10));
  };

  const HandleNameSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = FILTER_NAME;
    } else {
      filteredData = searchName?.filter((x) => {
        return (
          x?.label?.toLowerCase().charAt(0) === value?.toLowerCase().charAt(0)
        );
      });
    }
    setSearchName(filteredData);
  };

  const handleSearch = (value) => {
    let updatedData; // Initialize with the original data

    setSearchValue(value);
    if (!value) {
      setPayoutData(sidebarData);
    } else {
      updatedData = sidebarData?.filter(
        (sidebarData) =>
          sidebarData?.Name?.toLowerCase().charAt(0) ===
          value?.toLowerCase().charAt(0),
      );

      setPayoutData(updatedData);
    }
  };

  const getUniqueSDROwners = () => {
    const uniqueSDROwnerMap = {};

    sidebarData?.forEach((item) => {
      if (item.pipedrive_org_id) {
        uniqueSDROwnerMap[item.pipedrive_org_id] = item;
      }
    });

    return Object.values(uniqueSDROwnerMap);
  };

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setIsViewOpen(false)}
        visible={isViewOpen}
        bodyStyle={{ padding: "20px", marginBottom: "80px" }}
        className="custom-drawer"
      >
        <div className="w-full h-full relative ">
          <div className="flex justify-between items-center">
            <Text className="text-[#101828] text-[20px] font-semibold">
              Filters
            </Text>

            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
              alt=""
              className="w-[15px] cursor-pointer"
              onClick={() => setIsViewOpen(false)}
            />
          </div>
          <Text className="text-[#475467] text-[14px] font-normal">
            Apply filters to your payouts
          </Text>

          {/* Status Filter */}
          <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                Status
              </Text>
            </div>

            <div className="grid gap-y-3 mt-4 pl-2">
              {FILTER_STATUS1?.map((item, i) => (
                <Checkbox
                  key={i}
                  data={item}
                  onChange={(value) => handleCheckboxChange(`Status`, value)}
                  checked={tempCheckboxValues?.Status?.includes(item?.Value)}
                />
              ))}
            </div>
          </div>

          {/* Type Filter */}
          <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                User Type
              </Text>
            </div>

            <div className="grid gap-y-3 mt-4 pl-2">
              {FILTER_TYPE?.map((item, i) => (
                <Checkbox
                  key={i}
                  data={item}
                  onChange={(value) => {
                    handleCheckboxChange(`Role`, value);
                  }}
                  checked={tempCheckboxValues?.Role?.includes(item?.Value)}
                />
              ))}
            </div>
          </div>

          {/* Payout Amount Slider */}

          <div className="pb-6">
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px]  font-medium">
                Payout Amount
              </Text>
            </div>
            <div>
              <Slider
                value={
                  tempCheckboxValues?.Commission?.length
                    ? tempCheckboxValues?.Commission
                    : []
                }
                onChange={(value) => handleCheckboxChange(`Commission`, value)}
              />
            </div>
          </div>

          {/* Apply and Cancel Buttons */}

          <div className="flex justify-end items-cente gap-4 py-4 border-t border-[#EAECF0] fixed w-[362px] z-[1200] right-4 bottom-0 mt-7">
            <div className="px-4 flex gap-4">
              <Button
                onClick={() => {
                  setIsViewOpen(false);
                  setCheckboxValues({});
                  setTempCheckboxValues({});
                  setSliderValue([0, 10000]); // Reset slider to default values
                }}
                icon=""
                className="w-[100px] h-[44px] bg-[#fff] border border-[#D0D5DD] rounded-[8px] font-semibold text-[14px] text-[#344054]"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon=""
                disabled={isLoading}
                onClick={() => {
                  setCheckboxValues(tempCheckboxValues);
                  setIsViewOpen(false);
                }}
                className="w-[90px] h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {isLoading ? <Spin /> : "Apply"}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarFilter;
