import React, { useState } from "react";
import { Typography, Button, Table, Spin } from "antd";
import type { TableColumnsType } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import axiosClient from "../../utils/axios.ts";
import useOpenTeams from "../../hooks/use-teams.tsx";
import { toast } from "react-toastify";

const TableSection = ({
  label,
  btn,
  isEmploy,
  isAdmin,
  setIsAdminOpen,
  setIsEditOpen,
  setIsRemoveOpen,
  btnIcon,
  tableData,
  setSelectedValue,
  setEmployeeData,
  setUserType,
  setIsViewOpen,
}: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { Text } = Typography;
  const [selectedId, setSelectedId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDelLoading, setIsDelLoading] = useState(false);
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    //@ts-ignore
    const selectedIdsAndVerification = selectedRows?.map((row) => row?.user_id);
    //@ts-ignore

    setSelectedId(selectedIdsAndVerification);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  interface DataType {
    key: React.Key;
    name: any;
    role: any;
    email: string;
    remove: any;
    status: any;
  }
  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "column1",
      className: "w-auto capitalize",
    },
    {
      title: "Commission Model",
      dataIndex: "role",
      key: "column3",
      className: "w-auto max-w-[200px]",
    },

    {
      title: "",
      dataIndex: "remove",
      key: "column5",
      className:
        "text-right cursor-pointer text-[#475467] hover:text-[#D0D5DD] w-auto max-w-[140px]",
    },
  ];
  columns.splice(-4, 0, {
    title: "Id",
    dataIndex: "user_id",
    key: "column9",
    className: "w-auto max-w-[100px]",
  });
  columns.splice(-2, 0, {
    title: "Country",
    dataIndex: "country",
    key: "column7",
    className: "w-[200px]",
  });
  columns.splice(-1, 0, {
    title: "Status",
    dataIndex: "status",
    key: "column6",
    className: "w-auto max-w-[100px]",
  });
  const CustomName = ({ item }) => {
    return (
      <div className="flex items-center gap-3">
        <div className="w-[36px] h-[36px] rounded-[50px] overflow-hidden bg-gray-100">
          <img
            src={
              item?.profile_image ? item?.profile_image : "/images/profile.svg"
            }
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
        <Text className="text-[#101828] text-[14px] font-medium">
          {item.name}
        </Text>
      </div>
    );
  };

  const EditBtn = ({ item }) => {
    return (
      <>
        <Text
          onClick={() => (
            setIsEditOpen(true),
            setSelectedValue(item),
            setEmployeeData(item),
            setUserType("AFFILIATE"),
            setIsViewOpen(true)
          )}
          style={{ color: "#475467", fontWeight: "600" }}
          className="text-[14px] text-[#004EEB] hover:text-[#004eebb8] fontss_1 pl-4"
        >
          View
        </Text>
        <Text
          style={{ color: "#475467", fontWeight: "600" }}
          onClick={() => {
            setIsRemoveOpen(true);
            setSelectedValue(item);
            setEmployeeData(item);
          }}
          className={`text-[14px] text-[#475467]  fontss_1  pl-4 ${
            item.role === "Owner"
              ? "cursor-not-allowed text-[#D0D5DD]"
              : "hover:text-[#D92D20]"
          }`}
        >
          Remove
        </Text>
        <Text
          onClick={() => (
            setIsEditOpen(true),
            setSelectedValue(item),
            setEmployeeData(item),
            setUserType("AFFILIATE")
          )}
          className="text-[14px] text-[#004EEB] fontss_1 hover:text-[#004eebb8] font-semibold pl-4"
        >
          Edit
        </Text>
      </>
    );
  };

  const StatusBtn = (item) => {
    return (
      <Text
        className={` text-[14px] font-normal   w-auto rounded-[16px] p-1 px-3 ${
          item?.item?.is_verified
            ? "bg-[#ECFDF3] text-[#027A48]"
            : "bg-[#FFFAEB] text-[#B54708]"
        }`}
      >
        {item?.item?.is_verified ? "Approved" : "Pending"}
      </Text>
    );
  };

  const mapDataToTableFormat = (data: any[]) => {
    return tableData?.map((item, index) => ({
      ...item,
      key: index,
      user_id: item?.user_id,
      name: <CustomName item={item} />,
      country: item?.location,
      phone: item?.phone,
      status: <StatusBtn item={item} />,
      role: item?.commission_model_id?.name
        ? item?.commission_model_id?.name
        : "No Commission",
      email: item?.email,
      remove: <EditBtn item={item} />,
    }));
  };

  const tabledata: DataType[] = mapDataToTableFormat(tableData);

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const affiliateTableData: DataType[] = mapDataToTableFormat(tabledata);

  const totalItems = affiliateTableData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const paginatedData = tabledata?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const { getTeams } = useOpenTeams();

  const handleSubmit = () => {
    //@ts-ignore
    const userData = selectedId;

    axiosClient()
      .post("users/update-many", {
        user_ids: userData,
        is_verified: true,
        is_email_verified: false, //to be set true when user verifies through email
      })
      .then((res) => {
        setIsLoading(false);
        getTeams("AFFILIATES");
        setSelectedRowKeys([]);
        toast.success("Approved Successfully");
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsDelLoading(true);
    //@ts-ignore

    const userData = selectedId;

    axiosClient()
      .post("users/update-many", {
        user_ids: userData,
        isDelete: true,
      })
      .then((res) => {
        setIsDelLoading(false);
        getTeams("AFFILIATES");
        setSelectedRowKeys([]);
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        console.log("err", err);
        setIsDelLoading(false);
      });
  };

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
        <Text className="text-[#101828] text-[18px] font-semibold">
          Partners
        </Text>

        <div className="flex justify-end items-center gap-3">
          {selectedRowKeys?.length > 0 && (
            <>
              <Button
                onClick={handleSubmit}
                type="primary"
                disabled={isLoading}
                className=" h-[44px] flex items-center bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {isLoading ? (
                  <Spin className=" text-white" />
                ) : (
                  "Approve Selected"
                )}
              </Button>

              <Button
                // @ts-ignore
                onClick={handleDelete}
                disabled={isDelLoading}
                // type="none"
                className=" h-[44px] flex items-center bg-[#D92D20] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {isDelLoading ? (
                  <Spin className=" text-white" />
                ) : (
                  "Decline & Remove"
                )}
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        style={{
          boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
        }}
        className="rounded-[12px] border border-[#EAECF0] bg-white  overflow-hidden mt-8"
      >
        <Table
          className=" reviewTable rowes"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
        />

        <div
          className="w-full bg-white   items-center  px-4  flex justify-between gap-4"
          style={{ height: "64px" }}
        >
          <Text className="text-[#344054] text-[14px] font-medium ">
            Page {currentPage} of {totalPages}
          </Text>

          <div className="flex items-center gap-3 paginationBtn">
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSection;
