import axiosClient from "../utils/axios.ts";

class Payouts {
  async getAllPayouts() {
    try {
      const payouts = await axiosClient().get("users-commissions");

      return payouts.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const payoutApi = new Payouts();
