import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Down from "../../assets/images/down.png";
import Delete from "../../assets/images/deleted.png";
import { Col, Modal, Spin } from "antd";
import { Grid } from "@mui/material";
import LabelInput from "../../components/labelInput.tsx";
import TeamSelectDropdown from "./select-dropdown.tsx";
import FilledButton from "../../components/filledButton.tsx";
import axiosClient from "../../utils/axios.ts";
import { useAuthContext } from "../../context/auth-context.tsx";
import { toast } from "react-toastify";
import moment from "moment";
import PartnerDropdown from "./partner-dropdown.tsx";
import useOpenTeams from "../../hooks/use-teams.tsx";

export default function MiniPartnerTable() {
  const { isAuthenticated } = useAuthContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [mainData, setMainData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [ModalOpen, setModalOpen] = React.useState(false);
  const [selectedItem, setSelectedIteam] = React.useState(null);
  const [allCommissionModels, setAllCommissionModels] = React.useState([]);
  const [selectedMiniPartnerModel, setSelectedMiniPartnerModel] =
    React.useState(null);
  const [selectedSuperPartners, setSelectedSuperPartners] = React.useState<
    number[]
  >([]);
  const { getTeams } = useOpenTeams();

  //Pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 8;

  const totalItems = mainData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const paginatedData = mainData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const handlePageChange = (page) => setCurrentPage(page);

  interface MiniPartner {
    user_id: number;
    name: string;
    email: string;
    profile_image: string | null;
    role: string;
    location: string;
    commission_model_id: {
      commission_model_id: number;
      name: string;
    };
    super_partners: SuperPartner[];
  }

  interface SuperPartner {
    user_id: number;
    name: string;
    email: string;
    profile_image: string | null;
    role: string;
  }

  const getFormattedMiniPartners = async () => {
    await axiosClient()
      .get("users/mini-partners/formatted")
      .then((res) => {
        setMainData(res.data);
      })
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      axiosClient()
        .get("commission-model")
        .then((res) => {
          const models = res?.data
            ?.filter(
              (model: any) => model?.user_type_id?.type_name === "MINI_PARTNER",
            )
            .map((item: any) => ({
              value: item?.commission_model_id,
              label: item?.name,
            }));
          setAllCommissionModels(models);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    getFormattedMiniPartners();
  }, []);

  const showModal = (item: MiniPartner) => {
    setSelectedIteam(item);
    setSelectedMiniPartnerModel(item?.commission_model_id?.commission_model_id);
    setSelectedSuperPartners(
      item?.super_partners?.map((partner) => partner?.user_id),
    );
    setIsModalOpen(true);
  };

  const showRemoveModal = (item) => {
    setSelectedIteam(item);
    setModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setSelectedIteam(null);
    setSelectedMiniPartnerModel(null);
    setIsModalOpen(false);
    setModalOpen(false);
  };

  const handleChange = (newValue) => {
    setSelectedMiniPartnerModel(newValue);
  };

  const handleSuperPartnerChange = (newSelectedPartners: number[]) => {
    setSelectedSuperPartners(newSelectedPartners);
  };

  const removeHandler = () => {
    const userId = selectedItem?.user_id;
    setIsLoading(true);
    axiosClient()
      .delete(`/users/${userId}`)
      .then((res) => {
        getFormattedMiniPartners();
        toast.success(`${selectedItem?.name} removed successfully!`);
        setSelectedIteam(null);
        setModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      })
      .finally(() => {
        getTeams("MINI_PARTNER");
        setIsLoading(false);
      });
  };

  const submitHandler = () => {
    if (!selectedItem) return;

    const userId = selectedItem.user_id;
    const data = {
      commission_model_id: selectedMiniPartnerModel,
      super_partners: selectedSuperPartners,
    };
    setIsLoading(true);
    axiosClient()
      .patch(`/users/mini-partner/${userId}`, data)
      .then((res) => {
        toast.success("User Updated Successfully");
        getFormattedMiniPartners();
        setSelectedIteam(null);
        setSelectedMiniPartnerModel(null);
        setSelectedSuperPartners([]);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          className="bigTable"
        >
          <TableCell className="bg_1">
            <Checkbox />
          </TableCell>
          <TableCell scope="row" className="bg_2">
            {row?.user_id}
          </TableCell>
          <TableCell className="bg_3">
            <div className="profileSection">
              <img
                src={
                  row?.profile_image
                    ? row?.profile_image
                    : "/images/profile.svg"
                }
                alt=""
                className="object-cover w-full h-full"
              />{" "}
              {row?.name}
            </div>
          </TableCell>
          <TableCell className="bg_4">
            <span className="green_Bg">
              {" "}
              {row?.role === "MINI_PARTNER"
                ? "Mini Partner"
                : "Super Partner"}{" "}
            </span>
          </TableCell>
          <TableCell className="bg_5">{row?.location}</TableCell>
          <TableCell className="bg_6">
            {row?.commission_model_id?.name}
          </TableCell>
          <TableCell
            className="bg_8"
            style={{
              textAlign: "end",
              paddingRight: "32px",
            }}
          >
            <span onClick={() => showModal(row)} style={{ cursor: "pointer" }}>
              View
            </span>
            <span
              onClick={() => showRemoveModal(row)}
              style={{ cursor: "pointer" }}
            >
              Remove
            </span>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <img src={Down} alt="" />
              ) : (
                <img src={Down} alt="" className="rotate" />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className="innerBodys"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            colSpan={7}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="miniPartners">
                <Table className="innerTable" aria-label="details">
                  <TableBody>
                    {row.super_partners.map((superPartner) => (
                      <TableRow key={superPartner.id} className="innerSection">
                        <TableCell className="sm_1"></TableCell>
                        <TableCell className="sm_2">
                          {superPartner.user_id}
                        </TableCell>
                        <TableCell className="sm_3">
                          <div className="profileSection">
                            <img
                              src={
                                superPartner?.profile_image
                                  ? superPartner?.profile_image
                                  : "/images/profile.svg"
                              }
                              alt=""
                              className="object-cover w-full h-full"
                            />{" "}
                            {superPartner?.name}
                          </div>
                        </TableCell>
                        <TableCell className="sm_4">
                          <span className="blue_Bg">Super Partner</span>
                        </TableCell>
                        <TableCell className="sm_5"></TableCell>
                        <TableCell className="sm_6"></TableCell>
                        <TableCell className="sm_7"></TableCell>
                        <TableCell className="sm_8"></TableCell>
                        <TableCell className="sm_9"></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <TableContainer component={Paper} className="tableCollapse">
        <Table aria-label="collapsible table">
          <TableHead className="theadTable">
            <TableRow>
              <TableCell>
                <Checkbox />
              </TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Commission Model</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className="bigTableBody">
            {paginatedData.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>

        <div className="w-full bg-white h-20 items-center px-4 flex justify-between gap-4">
          <span className="text-[#344054] text-[14px] font-medium">
            Page {currentPage} of {totalPages}
          </span>

          <div className="flex items-center gap-3">
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            >
              Previous
            </button>
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            >
              Next
            </button>
          </div>
        </div>
      </TableContainer>

      <Modal
        className="modalViewPopup partner"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col className="headerView">
          <img
            src={
              selectedItem?.profile_image
                ? selectedItem?.profile_image
                : "/images/profile.svg"
            }
            alt=""
          />
          <Col>
            <h3>{selectedItem?.name}</h3>
            <h6>Id: {selectedItem?.user_id}</h6>
          </Col>
          <span className="bg_green">Mini Partner</span>
        </Col>
        <Grid container className="gridBox">
          <Grid xs={5.9}>
            <Col>
              <LabelInput title="Email" value={selectedItem?.email} disabled />
            </Col>
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Country"
                value={selectedItem?.location}
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Phone Number"
                value={selectedItem?.phone}
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Created At"
                value={
                  selectedItem?.created_at
                    ? moment(selectedItem?.created_at).format("MMMM Do YYYY")
                    : "NA"
                }
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput title="Last Login" placeholder="July 24th 2024" />
            </Col>
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Last Login"
                value={
                  selectedItem?.token_created_at
                    ? moment(selectedItem?.token_created_at).format(
                        "MMMM Do YYYY",
                      )
                    : "NA"
                }
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={12}>
            <TeamSelectDropdown
              options={allCommissionModels}
              title="Commission Model"
              value={allCommissionModels?.find(
                (model) =>
                  model?.value ===
                  (selectedMiniPartnerModel
                    ? selectedMiniPartnerModel
                    : selectedItem?.commission_model_id?.commission_model_id),
              )}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid xs={12}>
            <Col>
              <PartnerDropdown
                linkedSuperPartners={selectedItem?.super_partners}
                onChange={handleSuperPartnerChange}
              />
            </Col>
          </Grid>
        </Grid>
        <Col className="modalBtn">
          <FilledButton onClick={handleCancel}>Cancel</FilledButton>
          <FilledButton
            style={{ background: "#155EEF", color: "#fff" }}
            onClick={submitHandler}
          >
            {isLoading ? <Spin /> : "Save Changes"}
          </FilledButton>
        </Col>
      </Modal>

      {/* remove modal start */}
      <Modal
        className="modalViewPopup newRemove"
        open={ModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col className="headerView">
          <img src={Delete} alt="" />
          <Col>
            <h5>Remove {selectedItem?.name}?</h5>
            <p className="pText">
              Are you sure you want to remove them? This action cannot be
              undone.
            </p>
          </Col>
        </Col>

        <Col className="removeBtn">
          <FilledButton onClick={handleCancel}>Cancel</FilledButton>
          <FilledButton onClick={removeHandler}>
            {isLoading ? <Spin /> : "Remove"}
          </FilledButton>
        </Col>
      </Modal>
    </>
  );
}
