import React, { useState } from "react";
import { Typography, Button, Table, Modal, Row, Col } from "antd";
import type { TableColumnsType } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import NoPhoto from "../../assets/profile.svg";
import Closeds from "../../assets/images/closeds.png";
import LabelInput from "../../components/labelInput.tsx";
import moment from "moment";

const TableSection = ({
  label,
  btn,
  isEmploy,
  isAdmin,
  setIsAdminOpen,
  setIsEditOpen,
  setIsRemoveOpen,
  itemIndex,
  btnIcon,
  tableData,
  setSelectedValue,
  setEmployeeData,
}: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { Text } = Typography;
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Number of items per page

  interface DataType {
    key: React.Key;
    name: any;
    role: any;
    email: string;
    remove: any;
    status: any;
  }
  const columns: TableColumnsType<DataType> = [
    {
      title: "Name ",
      dataIndex: "name",
      key: "column1",
      className: "xl:w-[300px] w-auto capitalize",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "column2",
      className: `w-auto`,
    },
    {
      title: isAdmin ? "Role" : isEmploy && "Commission Model",
      dataIndex: "role",
      key: "column3",
      className: "xl:w-[300px] w-auto",
    },
    {
      title: "",
      dataIndex: "remove",
      key: "column5",
      className:
        "text-right cursor-pointer text-[#475467] hover:text-[#D0D5DD]",
    },
  ];

  const CustomName = ({ item }) => {
    return (
      <div className="flex items-center gap-3">
        <div className="w-[36px] h-[36px] rounded-[50px] overflow-hidden bg-gray-100">
          <img
            src={
              item?.profile_image ? item?.profile_image : "/images/profile.svg"
            }
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
        {item?.name ? (
          <Text className="text-[#101828] text-[14px] font-medium">
            {item?.name}
          </Text>
        ) : (
          <span className="badgeSpan">Invited</span>
        )}
      </div>
    );
  };

  const EditBtn = ({ item }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const openModal = () => {
      setIsModalVisible(true);
    };

    const closeModal = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        {!isEmploy && item?.name && (
          <>
            <Text
              onClick={() => {
                openModal();
              }}
              style={{ color: "#475467", fontWeight: "600" }}
              className="text-[14px] text-[#004EEB] hover:text-[#004eebb8] newTag pl-4"
            >
              View
            </Text>
          </>
        )}
        {isEmploy && item?.name && (
          <>
            <Text
              onClick={() => (
                setIsEditOpen(true),
                setSelectedValue(item),
                setEmployeeData(item)
              )}
              style={{ color: "#475467", fontWeight: "600" }}
              className="text-[14px]  newTag hover:text-[#004eebb8] font-semibold pl-4"
            >
              View
            </Text>
          </>
        )}
        <Text
          onClick={() => {
            setIsRemoveOpen(true);
            setSelectedValue(item);
            setEmployeeData(item);
          }}
          style={{ color: "#475467", fontWeight: "600" }}
          className={`text-[14px] text-[#475467]  newTag  pl-2 ${
            item.role === "Owner"
              ? "cursor-not-allowed text-[#D0D5DD]"
              : "hover:text-[#D92D20]"
          }`}
        >
          Remove
        </Text>
        {/* Admin View Modal */}
        <Modal
          className="viewModal"
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <div className="viewHeader">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={item?.profile_image ? item?.profile_image : NoPhoto}
                alt=""
                className="profilePic"
              />
              <h3>{item?.name}</h3>
            </div>
            <img
              src={Closeds}
              alt=""
              onClick={() => setIsModalVisible(false)}
              style={{ cursor: "pointer", position: "relative", right: "12px" }}
            />
          </div>
          <Row className="rowSection">
            <Col md={24}>
              <Col style={{ margin: "0 6px" }}>
                <LabelInput title="Email" value={item?.email} disabled />
              </Col>
            </Col>
            <Col md={12}>
              <Col className="rowInput">
                <LabelInput
                  title="Created At"
                  value={
                    item?.created_at
                      ? moment(item?.created_at).format("MMMM Do YYYY")
                      : "NA"
                  }
                  disabled
                />
              </Col>
            </Col>

            <Col md={12}>
              <Col className="rowInput">
                <LabelInput
                  title="Last Login"
                  disabled
                  value={
                    item?.token_created_at
                      ? moment(item?.token_created_at).format("MMMM Do YYYY")
                      : "NA"
                  }
                />
              </Col>
            </Col>
          </Row>
        </Modal>
      </>
    );
  };

  const StatusBtn = () => {
    return (
      <Text className="text-[#027A48] text-[14px] font-normal  bg-[#ECFDF3] w-auto rounded-[16px] p-1 px-3">
        Converted
      </Text>
    );
  };

  const mapDataToTableFormat = (data: any[]) => {
    return data?.map((item, index) => ({
      ...item,
      key: index,
      user_id: item?.user_id,
      name: <CustomName item={item} />,
      Location: item?.location,
      user_summary: item?.user_summary,
      status: <StatusBtn />,
      role: isEmploy
        ? item.commission_model_id?.name || "-" //(item.role ? item.role : "")
        : item.role || "",
      email: item.email,
      created: item?.created_at
        ? moment(item?.created_at).format("DD/MM/YYYY")
        : "0/0/0000",
      remove: <EditBtn item={item} />,
    }));
  };

  const tabledata: DataType[] = mapDataToTableFormat(tableData);

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const ButtonImage = () => {
    return <>{btnIcon && <img src={btnIcon} alt="" />}</>;
  };

  const affiliateTableData: DataType[] = mapDataToTableFormat(tabledata);

  const totalItems = affiliateTableData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const paginatedData = tabledata?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center ">
        <Text className="text-[#101828] text-[18px] font-semibold ">
          {label}
        </Text>

        <div className="flex justify-end items-center gap-3">
          {btn !== "Approve Selected" && (
            <>
              <Button
                onClick={() => setIsAdminOpen(true)}
                type="primary"
                icon={btnIcon && <ButtonImage />}
                className=" h-[44px] flex items-center bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {btn}
              </Button>
            </>
          )}
          {!btnIcon && btn !== "Approve Selected" && (
            <>
              <Button
                // @ts-ignore
                type="none"
                className=" h-[44px] flex items-center bg-[#D92D20] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                Decline
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        style={{
          boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
        }}
        className="rounded-[12px] border border-[#EAECF0] bg-white  overflow-hidden mt-6"
      >
        <Table
          rowSelection={rowSelection}
          columns={columns}
          className=" reviewTable rowes"
          dataSource={paginatedData}
          pagination={false}
        />

        <>
          <div
            className="w-full bg-white  items-center  px-4  flex justify-between gap-4"
            style={{ height: "64px" }}
          >
            <Text className="text-[#344054] text-[14px] font-medium ">
              Page {currentPage} of {totalPages}
            </Text>

            <div className="flex items-center gap-3 paginationBtn">
              <button
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              <button
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default TableSection;
