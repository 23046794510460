"use client";
import React, { useState, useEffect } from "react";
import { Typography, Table, Spin } from "antd";
import type { TableColumnsType } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import axiosClient from "../../utils/axios.ts";

interface DataType {
  Date: any;
  Time: any;
  Module: any;
  Context: any;
  Error: any;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Date",
    dataIndex: "date",
    className: "capitalize",
  },
  {
    title: "Time",
    dataIndex: "time",
  },
  {
    title: "Module",
    dataIndex: "module",
  },
  {
    title: "Context",
    dataIndex: "context",
  },
  {
    title: "Error Message",
    dataIndex: "error",
  },
];

const ErrorLogsTable = ({ errorLogs }: any) => {
  const isLoading = useSelector(
    (state: RootState) => state.isLoading.isLoading,
  );
  const { Text } = Typography;

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Number of items per page

  const mapDataToTableFormat = (data: any[]) => {
    return data?.map((item, index) => {
      const dateObject = new Date(item?.timestamp);
      const date = dateObject.toISOString().split("T")[0];
      const time = dateObject.toISOString().split("T")[1].split(".")[0];

      return {
        ...item,
        key: index,
        date: date,
        time: time,
        module: `${item?.module}`,
        context: `${item?.context}`,
        error: `${item?.message}`,
      };
    });
  };

  const ErrorLogsTableData: any[] = mapDataToTableFormat(errorLogs);

  const totalItems = ErrorLogsTableData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const paginatedData = ErrorLogsTableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  return (
    <div>
      {isLoading ? (
        <>
          <div className="flex items-center justify-center py-20">
            <Spin />
          </div>
        </>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={paginatedData}
            pagination={false}
          />
          <div
            className="w-full bg-white  items-center  px-4  flex justify-between gap-4"
            style={{ height: "64px" }}
          >
            <Text className="text-[#344054] text-[14px] font-medium ">
              Page {currentPage} of {totalPages}
            </Text>

            <div className="flex items-center gap-3 paginationBtn">
              <button
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              <button
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ErrorLogsTable;
