// @ts-nocheck
import { Box, Grid } from "@mui/material";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import moment from "moment";
import Pencil from "../../../../assets/images/pencil.png";
import User from "../../../../assets/images/user.png";
import Close from "../../../../assets/images/xx.png";
import BigUser from "../../../../assets/images/biglogo.png";
import Touch from "../../../../assets/images/touch.png";
import Leave from "../../../../assets/images/leaves.png";
import Circle from "../../../../assets/images/plus-circle.png";
import Coin from "../../../../assets/images/coins.png";
import Office from "../../../../assets/images/office.png";
import Bank from "../../../../assets/images/bank.png";
import Discount from "../../../../assets/images/discount.png";
import Dollar from "../../../../assets/images/dollar.png";
import UserAdd from "../../../../assets/images/useradd.png";
import LabelInput from "../../../../components/commissionLabelInput.tsx";
import FilledButton from "../../../../components/filledButton.tsx";
import FilterComponent from "../filters/index.tsx";
import { toast } from "react-toastify";
import axiosClient from "../../../../utils/axios.ts";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Modal, Spin } from "antd";

//Debounce utility function
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export default function MiniPartnerApproval({ data, refresh }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [events, setEvents] = useState(data || []);
  const [filters, setFilters] = useState({
    minAmount: null,
    maxAmount: null,
    companyName: null,
    affiliate: null,
    revenueType: null,
    dateRange: null,
  });

  const [openEvent, setOpenEvent] = useState(events[0] || null);
  const [commission, setCommission] = useState("0.00");
  const [revenueAmount, setRevenueAmount] = useState("0.00");
  const [commissionAmount, setCommissionAmount] = useState("0.00");
  const [commissionPercentage, setCommissionPercentage] = useState(() => {
    return openEvent ? openEvent.percentage : 0;
  });
  const [isExceeding, setIsExceeding] = useState(false);
  const [isExceeding1, setIsExceeding1] = useState(false);
  const [linkedSuperPartners, setLinkedSuperPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const getLinkedSuperPartners = (id) => {
    axiosClient()
      .get(`users/mini-partner/${id}/linked-super-partners`)
      .then((res) => {
        setLinkedSuperPartners(res.data);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => {
    const filteredEvents = data.filter((event) => {
      return (
        (!filters.minAmount ||
          parseFloat(event.fee) >= parseFloat(filters.minAmount)) &&
        (!filters.maxAmount ||
          parseFloat(event.fee) <= parseFloat(filters.maxAmount)) &&
        (!filters.companyName ||
          event.organizationName === filters.companyName) &&
        (!filters.affiliate || event.partnerName === filters.affiliate) &&
        (!filters.revenueType || event.feeType === filters.revenueType) &&
        (!filters.dateRange ||
          (new Date(event.date) >= filters.dateRange.startDate &&
            new Date(event.date) <= filters.dateRange.endDate))
      );
    });
    setEvents(filteredEvents);

    // Update openEvent and related states when filters change
    if (filteredEvents.length > 0) {
      const newOpenEvent = filteredEvents[0];
      handleItemClick(filteredEvents[0]);
      setOpenEvent(newOpenEvent);
      setRevenueAmount(newOpenEvent?.fee);
      const newCommission =
        (newOpenEvent?.fee * newOpenEvent?.percentage) / 100;
      setCommission(newCommission?.toFixed(2));
      setCommissionAmount(newCommission?.toFixed(2));
      getLinkedSuperPartners(newOpenEvent?.partnerId);
    } else {
      setOpenEvent(null);
      setRevenueAmount("0.00");
      setCommission("0.00");
      setCommissionAmount("0.00");
      setLinkedSuperPartners([]);
    }
  }, [data, filters]);

  const handleItemClick = (item) => {
    setIsLoading(true);
    setOpenEvent(item);
    setRevenueAmount(item.fee);
    setCommissionPercentage(item.percentage);
    const newCommission = (item.fee * item.percentage) / 100;
    setCommission(newCommission.toFixed(2));
    setCommissionAmount(newCommission.toFixed(2));
    getLinkedSuperPartners(item.partnerId);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const handleRevenueAmountChange = (e) => {
    const newRevenueAmount = e.target.value;
    setRevenueAmount(newRevenueAmount);

    if (openEvent) {
      const newCommissionAmount =
        (newRevenueAmount * openEvent.percentage) / 100;
      setCommissionAmount(newCommissionAmount.toFixed(2));
      setCommission(newCommissionAmount.toFixed(2));
    }
  };

  const handleCommissionPercentageChange = (e) => {
    const newPercentage = e.target.value;
    setCommissionPercentage(newPercentage);

    if (openEvent) {
      const newCommissionAmount = (revenueAmount * newPercentage) / 100;
      setCommissionAmount(newCommissionAmount.toFixed(2));
      setCommission(newCommissionAmount.toFixed(2));
    }
  };

  useEffect(() => {
    if (parseFloat(revenueAmount) > parseFloat(openEvent?.fee)) {
      setIsExceeding(true);
    } else {
      setIsExceeding(false);
    }
  }, [commissionAmount, revenueAmount]);

  useEffect(() => {
    if (parseFloat(commissionPercentage) > parseFloat(openEvent?.percentage)) {
      setIsExceeding1(true);
    } else {
      setIsExceeding1(false);
    }
  }, [commissionAmount, commissionPercentage]);

  const capitalize = (str) =>
    str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  const cardDetails = openEvent
    ? [
        { img: Touch, title: "Partner ID", value: `${openEvent?.partnerId}` },
        {
          img: Leave,
          title: "Super Partner Name",
          value: (
            <>
              <span
                onClick={showModal}
                style={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "4px 0 0",
                }}
              >
                {linkedSuperPartners ? linkedSuperPartners[0]?.name : ""}
                <img src={Circle} alt="" style={{ marginLeft: "4px" }} />
              </span>
            </>
          ),
        },
        {
          img: Coin,
          title: "Revenue Event",
          value: openEvent?.feeType ? capitalize(openEvent?.feeType) : "",
        },
        {
          img: Office,
          title: "Company Name",
          value: openEvent.organizationName,
        },
        { img: Bank, title: "Commission Amount", value: `${commission} USD` },
        {
          img: Discount,
          title: "Commission %",
          value: `${openEvent?.percentage}%`,
        },
      ]
    : [];

  const resetForm = () => {
    if (events.length > 1) {
      const nextEvent = events.find(
        (event) => event.transactionId !== openEvent.transactionId,
      );
      if (nextEvent) {
        handleItemClick(nextEvent);
      }
    } else {
      setOpenEvent(null);
      setRevenueAmount("0.00");
      setCommission("0.00");
      setCommissionAmount("0.00");
    }
    refresh();
  };

  const debouncedArchive = useCallback(
    debounce(async () => {
      if (!openEvent) return;

      setIsLoading1(true);
      try {
        const ID = openEvent.transactionId;
        await axiosClient().patch(`transactions/${ID}`, {
          field: "is_archived",
          value: true,
        });
        toast.success("Transaction archived successfully");
        resetForm();
      } catch (error) {
        toast.error(error.message || "An error occurred while archiving");
        console.error("Error in archiveHandler:", error);
      } finally {
        setIsLoading1(false);
      }
    }, 500),
    [openEvent],
  );

  const selectOrganization = useMemo(() => {
    return createSelector(
      [(state) => state.organization.organization, (_, orgId) => orgId],
      (organizations, orgId) => {
        return organizations.find((org) => org.ID === orgId);
      },
    );
  }, []);

  const selectedOrganization = useSelector((state) =>
    selectOrganization(state, openEvent ? +openEvent?.organizationId : null),
  );

  // Separated async function for getting organization data
  const getOrganizationTableId = async () => {
    try {
      if (selectedOrganization) {
        return {
          tableId: selectedOrganization?.table_id,
          status: selectedOrganization?.Status,
        };
      }

      const response = await axiosClient().get(
        `pipedrive-organisations/find-by-id/${+openEvent?.organizationId}`,
      );
      const org = response.data;
      return {
        tableId: org?.table_id,
        status: org?.Status,
      };
    } catch (error) {
      console.error("Error fetching organization data:", error);
      throw error;
    }
  };

  const debouncedSubmit = useCallback(
    debounce(async () => {
      if (!openEvent) return;

      setIsLoading2(true);
      try {
        const { tableId, status } = await getOrganizationTableId();

        const payload = {
          newCommission: {
            commission: +commissionPercentage,
            user_id: +openEvent?.partnerId,
            pipedrive_org_id: tableId,
            commission_paid: +commissionAmount,
            total_revenue: +revenueAmount,
            last_revenue: 0,
            revenue_event: openEvent?.feeType,
            is_approved: false,
            status: status,
            payment_status: "Unpaid",
            created_at: new Date(openEvent?.date),
            transaction_id: openEvent.transactionId,
          },
          commissionType: "PARTNER",
        };

        const response = await axiosClient().post(
          "users-commissions/process/new",
          payload,
        );
        toast.success(response?.data.message);
        resetForm();
      } catch (error) {
        toast.error(
          error.data?.message || "An error occurred during submission",
        );
        console.error("Error in submitHandler:", error);
      } finally {
        setIsLoading2(false);
      }
    }, 500),
    [openEvent, commissionPercentage, commissionAmount, revenueAmount],
  );

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <>
      <Box>
        <h3 className="totalEvents">{events.length} Events</h3>
        <FilterComponent
          data={data}
          onFilterChange={handleFilterChange}
          refresh={refresh}
          isMini={true}
          handleItemClick={handleItemClick}
        />
        <Grid container spacing={1} className="gridSide">
          <Grid item xs={3} paddingRight={"15px"} className="mobileFit">
            {events.map((item, index) => (
              <Box
                className={
                  item.transactionId === openEvent?.transactionId
                    ? "sideMenu-active"
                    : "sideMenu"
                }
                onClick={() => handleItemClick(item)}
                key={index}
              >
                <Box
                  className="userName"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img src={Dollar} alt="" />
                  <Box>
                    <h6>{item?.organizationName}</h6>
                    <h5>{item?.partnerName}</h5>
                  </Box>
                </Box>
                <Box className="userName last">
                  <h6>
                    {item?.date
                      ? moment(item?.date).format("DD/MM/YYYY")
                      : "0/0/0000"}
                  </h6>
                  <h5 style={{ fontWeight: "400" }}>${item?.fee}</h5>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={9} className="mobileFits">
            {openEvent ? (
              <Box className="rightCard">
                {!isLoading ? (
                  <>
                    <Box className="rightHeader">
                      <Box className="leftSide">
                        <img src={BigUser} alt="" />
                        <Box>
                          <span className="partnerBadge">Mini Partner</span>
                          <h4>{openEvent?.partnerName}</h4>
                        </Box>
                      </Box>
                      <Box className="rightSide">
                        <h4>
                          {openEvent?.date
                            ? moment(openEvent?.date).format("DD/MM/YYYY")
                            : "0/0/0000"}
                        </h4>
                        <h6>{openEvent?.fee} USD</h6>
                      </Box>
                    </Box>
                    <Box className="cardBody">
                      <Box className="gridSection">
                        {cardDetails.map((detail, index) => (
                          <Box className="cardDetail" key={index}>
                            <img src={detail.img} alt="" />
                            <h5>{detail.title}</h5>
                            <h6>{detail.value}</h6>
                          </Box>
                        ))}
                      </Box>
                      <Grid
                        container
                        style={{ borderBottom: "1px solid #EAECF0" }}
                      >
                        <Grid xs={5.9}>
                          <Box
                            className="revenueInput"
                            sx={{ position: "relative", borderBottom: "0" }}
                          >
                            <LabelInput
                              title="Revenue Amount"
                              value={revenueAmount}
                              onChange={handleRevenueAmountChange}
                              type="number"
                            />
                            <img src={Pencil} alt="" />
                            {isExceeding ? (
                              <text className="text-red-400 text-sm">
                                Exceeding the real Revenue Amount
                              </text>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                        <Grid xs={0.2}></Grid>
                        <Grid xs={5.9}>
                          <Box
                            className="revenueInput"
                            sx={{ position: "relative", borderBottom: "0" }}
                          >
                            <LabelInput
                              title="Commission %"
                              type="number"
                              value={commissionPercentage}
                              onChange={handleCommissionPercentageChange}
                            />
                            <img src={Pencil} alt="" />
                            {isExceeding1 ? (
                              <text className="text-red-400 text-sm">
                                Exceeding the real Commission Percentage
                              </text>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Box className="submitButton">
                        <FilledButton
                          disabled={
                            isExceeding ||
                            isExceeding1 ||
                            !openEvent ||
                            isLoading ||
                            isLoading1 ||
                            isLoading2
                          }
                          onClick={debouncedArchive}
                        >
                          {isLoading1 ? <Spin /> : "Archive"}
                        </FilledButton>
                        <FilledButton
                          style={{ background: "#000", color: "#fff" }}
                          disabled={
                            isExceeding ||
                            isExceeding1 ||
                            !openEvent ||
                            isLoading ||
                            isLoading1 ||
                            isLoading2
                          }
                          onClick={debouncedSubmit}
                        >
                          {isLoading2 ? <Spin /> : "Submit"}
                        </FilledButton>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Spin />
                  </div>
                )}
              </Box>
            ) : (
              <Box className="rightCard">
                <p>No events to display</p>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {(isLoading1 || isLoading2) && <Spin fullscreen />}

      <Modal
        className="modalPartner"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="headerModal">
          <h3>Super Partners</h3>
          <img src={Close} alt="" onClick={() => setIsModalOpen(false)} />
        </div>
        <div className="ulBody">
          <ul>
            {linkedSuperPartners &&
              linkedSuperPartners?.map((item, index) => (
                <li key={index} className="flex items-center space-x-4">
                  <img
                    src={item.profile_image || BigUser}
                    alt={item.name}
                    className="w-10 h-10 rounded-full"
                  />
                  <span>{item.name}</span>
                </li>
              ))}
          </ul>
        </div>
      </Modal>
    </>
  );
}
