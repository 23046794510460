import * as React from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface DatePickerI {
  onChange: (dateValue: any) => void;
}

export default function StaticDatePickerLandscape({ onChange }: DatePickerI) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} className="static">
      <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]}>
        <DatePicker
          views={["year", "month", "day"]}
          format="DD/MM/YYYY"
          onChange={(date) => onChange(date?.toDate())}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
