import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function SelectDropdown({ options, title, value, onChange }) {
  // Find the option object that matches the current value
  const selectedOption =
    options.find((option) => option.value === value) || null;

  return (
    <>
      <label>{title}</label>
      <Autocomplete
        disablePortal
        options={options}
        value={selectedOption}
        disableClearable
        onChange={(event, newValue) => {
          onChange(newValue.value);
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
}
