// @ts-nocheck
"use client";
import React, { useState } from "react";
import { Table, Button, Typography, Spin, Modal, Row, Col } from "antd";
import type { TableProps } from "antd";
import moment from "moment";
import Close from "../../../../assets/images/closeds.png";
import Avatar from "../../../../assets/images/user.png";
import Profile from "../../../../assets/images/avatar.png";
import LabelInput from "../../../../components/labelInput.tsx";

interface DataType {
  key: React.Key;
  id: number;
  name: string;
  userType: string;
  revenueEvent: string;
  date: string;
  revenueAmount: string;
  company: string;
  view: any;
}

type TableRowSelection<T> = TableProps<T>["rowSelection"];

const TableApp: React.FC = ({
  setIsViewOpen,
  setSelectedObject,
  isShowRow,
  data,
}: any) => {
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const ButtonComponent = (item) => {
    return (
      <div className="flex items-center gap-4 justify-center ">
        <button
          className="font-semibold text-[#475467] hover:text-[#004EEB]"
          onClick={() => {
            setModalContent(item);
            setIsModalVisible(true);
          }}
        >
          View
        </button>
      </div>
    );
  };

  const NameComponent = (item) => {
    return (
      <div className="flex items-center gap-3">
        <div className="w-[36px] h-[36px] rounded-[50px] overflow-hidden bg-gray-100">
          <img
            className="object-cover w-full h-full"
            src={item?.item?.profile_image || Avatar}
            alt=""
          />
        </div>
        <Text className="text-[#101828] text-[14px] font-medium">
          {item?.item?.name}
        </Text>
      </div>
    );
  };

  const capitalize = (str) =>
    str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  const formatTableData = (data) => {
    return data.map((item, index) => ({
      key: item.user_commission_id,
      id: item.user_id,
      name: <NameComponent item={item} />,
      userType: item.user_role
        ? item.user_role === "EMPLOYEE"
          ? "Employee"
          : item.user_role === "AFFILIATES"
            ? "Partner"
            : item.user_role === "MINI_PARTNER"
              ? "Mini Partner"
              : item.user_role === "SUPER_PARTNER"
                ? "Super Partner"
                : ""
        : "",
      revenueEvent: item.revenue_event ? capitalize(item.revenue_event) : "",
      date: item.created_date
        ? moment(item.created_date).format("MMMM Do YYYY")
        : "",
      revenueAmount: item.revenue_amount
        ? `$${(+item.revenue_amount).toFixed(2)}`
        : "",
      commision: item.commission_percentage
        ? `${(+item.commission_percentage).toFixed(2)}%`
        : "",
      amount: item.commission_amount
        ? `$${(+item.commission_amount).toFixed(2)}`
        : "",
      company: item.company,
      view: <ButtonComponent item={item} />,
    }));
  };

  const formattedData = formatTableData(data);

  const totalItems = formattedData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = formattedData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 === 0,
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 !== 0,
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleApprove = () => {
    setIsLoading(true);

    // Simulating API call
    setTimeout(() => {
      setIsLoading(false);
      setSelectedRowKeys([]);
    }, 1000);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Revenue Event",
      dataIndex: "revenueEvent",
      key: "revenueEvent",
    },
    {
      title: "Revenue Amount",
      dataIndex: "revenueAmount",
      key: "revenueAmount",
    },
    {
      title: "Revenue Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Commission %",
      dataIndex: "commision",
      key: "commision",
    },
    {
      title: "Commission Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "",
      dataIndex: "view",
      key: "view",
    },
  ];

  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
      }}
      className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden"
    >
      <Table
        rowSelection={isShowRow ? false : rowSelection}
        columns={columns}
        dataSource={paginatedData}
        className="reviewTable"
        style={{
          borderTop: "1px solid #EAECF0",
        }}
        pagination={false}
      />
      <div
        className="w-full bg-white  items-center  px-4  flex justify-between gap-4"
        style={{ height: "64px" }}
      >
        <Text className="text-[#344054] text-[14px] font-medium ">
          Page {currentPage} of {totalPages}
        </Text>

        <div className="flex items-center gap-3 paginationBtn">
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <button
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>

      <Modal
        className="viewPopup modals"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="headerDesktop">
          <img
            src={modalContent?.item?.profile_image || Avatar}
            className="profilePic"
            alt=""
          />
          <div>
            <h3>{modalContent?.item?.name}</h3>
            <p>
              {modalContent?.item?.user_role
                ? modalContent?.item?.user_role === "EMPLOYEE"
                  ? "Employee"
                  : modalContent?.item?.user_role === "AFFILIATES"
                    ? "Partner"
                    : modalContent?.item?.user_role === "MINI_PARTNER"
                      ? "Mini Partner"
                      : modalContent?.item?.user_role === "SUPER_PARTNER"
                        ? "Super Partner"
                        : ""
                : ""}
            </p>
          </div>
          <img
            src={Close}
            alt=""
            onClick={() => setIsModalVisible(false)}
            style={{ position: "absolute", right: "28px", cursor: "pointer" }}
          />
        </div>
        <div className="bodyModal">
          <Row
            style={{
              position: "relative",
              left: "5px",
            }}
          >
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Revenue Event"
                  placeholder=""
                  value={
                    modalContent?.item?.revenue_event
                      ? capitalize(modalContent?.item?.revenue_event)
                      : ""
                  }
                  readonly
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Revenue amount"
                  placeholder=""
                  value={
                    modalContent?.item?.revenue_amount
                      ? `$${(+modalContent?.item?.revenue_amount).toFixed(2)}`
                      : ""
                  }
                  readonly
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Reviewed Date"
                  placeholder=""
                  value={
                    modalContent?.item?.created_date
                      ? moment(modalContent?.item?.review_date).format(
                          "MMMM Do YYYY",
                        )
                      : ""
                  }
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Company Name"
                  placeholder=""
                  value={modalContent?.item?.company}
                  readonly
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Commission Amount"
                  value={
                    modalContent?.item?.commission_amount
                      ? `$${(+modalContent?.item?.commission_amount).toFixed(
                          2,
                        )}`
                      : ""
                  }
                  placeholder="$30"
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Commission %"
                  value={
                    modalContent?.item?.commission_percentage
                      ? `${(+modalContent?.item.commission_percentage).toFixed(
                          2,
                        )}%`
                      : ""
                  }
                  placeholder="30%"
                />
              </Col>
            </Col>
            <Col md={24}>
              <Col>
                <LabelInput
                  title="Revenue Generation Date"
                  placeholder=""
                  value={
                    modalContent?.item?.review_date
                      ? moment(modalContent?.item?.created_date).format(
                          "MMMM Do YYYY",
                        )
                      : ""
                  }
                  style={{ width: "98%" }}
                  created
                />
              </Col>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default TableApp;
