"use client";
import React, { useState, useEffect } from "react";
import { Table, Button, Typography, Spin } from "antd";
import type { TableColumnsType } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import axiosClient from "../../utils/axios.ts";

interface DataType {
  key: React.Key;
  name: any;
  percentage: any;
  lifetime: any;
  type: any;
  status: any;
  edit: any;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "name",
    className: "capitalize",
  },
  {
    title: "Percentage",
    dataIndex: "percentage",
  },
  {
    title: "Lifetime Commission",
    dataIndex: "lifetime",
  },
  {
    title: "User Type",
    dataIndex: "type",
  },
  {
    title: "",
    dataIndex: "status",
  },
  {
    title: "",
    dataIndex: "edit",
    className: "text-right cursor-pointer text-[#475467] hover:text-[#D0D5DD]",
  },
];

const TableSection = ({
  commissions,
  setIsRemoveOpen,
  setIsEditOpen,
  setCommissionId,
  setCommissionData,
}: any) => {
  const { Text } = Typography;
  const isLoading = useSelector(
    (state: RootState) => state.isLoading.isLoading,
  );

  const ButtonImage = () => {
    return (
      <>
        <img
          src="https://ik.imagekit.io/8extk8sjo/Icon%20(28).svg?updatedAt=1709815692870"
          alt=""
        />
      </>
    );
  };

  const StatusBtn = (item) => {
    if (item?.item?.is_default) {
      return (
        <Text
          className={` text-[14px] font-semibold defautBtn w-auto rounded-[16px] p-1 px-3 ${
            item?.item?.is_default
              ? "text-[#027A48] bg-[#ECFDF3]"
              : "text-[#344054] bg-[#F2F4F7]"
          }`}
        >
          {item?.item?.is_default ? "Default" : ""}
        </Text>
      );
    }
  };

  const EditBtn = (item: any) => {
    return (
      <>
        <Text
          onClick={() => {
            if (!item?.item?.is_default && item?.item?.number_of_user === 0) {
              setIsRemoveOpen(true);
              setCommissionId(item?.item?.commission_model_id);
              setCommissionData(item?.item);
            }
          }}
          className={`text-[14px]  font-semibold pl-2  ${
            !(
              item?.item?.is_default === false &&
              item?.item?.number_of_user === 0
            )
              ? "cursor-not-allowed text-[#D0D5DD] hover:text-[#D0D5DD]"
              : "hover:text-[#D92D20] cursor-pointer text-[#475467]"
          }`}
        >
          Remove
        </Text>
        <Text
          onClick={() => {
            setIsEditOpen(true);
            setCommissionData(item?.item);
          }}
          className="text-[14px] text-[#475467] hover:text-[#004EEB] font-semibold pl-4 cursor-pointer"
        >
          Edit
        </Text>
      </>
    );
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getRoleName = (role: string) => {
    if (role === "AFFILIATES") {
      return capitalize("PARTNER");
    } else if (role === "EMPLOYEE") {
      return capitalize("EMPLOYEE");
    } else if (role === "SUPER_PARTNER") {
      return capitalize("SUPER PARTNER");
    } else if (role === "MINI_PARTNER") {
      return capitalize("MINI PARTNER");
    } else {
      return role; //return as it is if does not match any of these values.
    }
  };

  const data: DataType[] = commissions?.map((item: any) => ({
    key: item?.commission_model_id,
    name: item?.name,
    percentage: `${item?.percentage}%`,
    lifetime: `${item?.is_life_time_comm ? "Yes" : "No"}`,
    type: `${
      item?.user_type_id?.type_name
        ? getRoleName(item?.user_type_id?.type_name)
        : ""
    } (${item?.number_of_user})`,
    status: <StatusBtn item={item} />,
    edit: <EditBtn item={item} />,
  }));

  return (
    <div>
      {isLoading ? (
        <>
          <div className="flex items-center justify-center py-20">
            <Spin />
          </div>
        </>
      ) : (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
          <div className="flex justify-end pt-4">
            <Button
              onClick={() => setIsEditOpen(true)}
              type="primary"
              icon={<ButtonImage />}
              className="h-[44px] flex items-center addModels bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
            >
              Add Model
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TableSection;
