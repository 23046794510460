import React, { useState, useEffect, useMemo } from "react";
import { Modal, Typography, Button, Input, Select, Spin, Row, Col } from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import useOpenTeams from "../../hooks/use-teams.tsx";
import Close from "../../assets/images/close.png";
import LabelInput from "../../components/labelInput.tsx";
import NoPhoto from "../../assets/profile.svg";
import moment from "moment";

const AffiliateModalSection = ({
  isOpen,
  setIsOpen,
  employeeData,
  setEmployeeData,
  isView,
  setIsViewOpen,
}) => {
  // console.log("Employee Data: ", employeeData);
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [commissionModels, setCommissionModels] = useState([]);

  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((country) => ({
          value: country.label,
          label: country.label,
        })),
    [],
  );

  const { getTeams } = useOpenTeams();

  const refresh = () => {
    getTeams("ADMIN");
    getTeams("AFFILIATES");
    getTeams("EMPLOYEE");
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    location: Yup.string().required("Country is required"),
    commission_model_id: Yup.number().required("Commission model is required"),
    phone: Yup.string()
      .nullable()
      .matches(
        /^[+\d\s]+$/,
        "Phone number can only contain numbers, +, and spaces",
      ),
  });

  useEffect(() => {
    axiosClient()
      .get("commission-model")
      .then((res) => {
        setCommissionModels(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  if (!employeeData) {
    return null;
  }

  const selectOptions = commissionModels
    ?.filter((item: any) => item?.user_type_id?.type_name === "AFFILIATES")
    .map((item) => ({
      value: item?.commission_model_id,
      label: item?.name,
    }));

  const initialValues = {
    email: employeeData.email || "",
    location: employeeData.location || "",
    commission_model_id:
      employeeData.commission_model_id?.commission_model_id || "",
    phone: employeeData.phone || "",
  };

  return (
    <Modal
      centered
      className="viewModal"
      open={isOpen}
      width={400}
      footer={null}
      closable={false}
      wrapClassName="custom-modal"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setIsLoading(true);
          axiosClient()
            .patch(`users/${employeeData.user_id}`, values)
            .then((res) => {
              if (res?.data) {
                toast.success("User updated successfully");
                setIsOpen(false);
                setEmployeeData(null);
                refresh();
                setIsViewOpen(false);
              }
            })
            .catch((err) => {
              console.log("err", err);
              toast.error(err.response?.data?.message);
            })
            .finally(() => {
              setIsLoading(false);
              setSubmitting(false);
            });
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <div className="viewHeader">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    employeeData?.profile_image
                      ? employeeData?.profile_image
                      : NoPhoto
                  }
                  alt=""
                  className="profilePic"
                />
                <div>
                  <h3>{employeeData?.name}</h3>
                  <span>Id: {employeeData?.user_id}</span>
                </div>
              </div>
              <img
                src={Close}
                alt=""
                onClick={() => {
                  setIsOpen(false);
                  setEmployeeData(null);
                  setIsViewOpen(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="py-1 px-4 border-b">
              <Row className="rowSection">
                <Col md={12}>
                  <div className="mb-4 rowInput">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Email
                    </Text>
                    <Field
                      type="email"
                      name="email"
                      as={Input}
                      disabled
                      className="w-full h-[44px] mt-2"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <Col className="rowInput">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Country
                    </Text>
                    <Select
                      name="location"
                      options={countryOptions}
                      value={values.location}
                      onChange={(value) => setFieldValue("location", value)}
                      className="w-full h-[44px] mt-0"
                      disabled={isView}
                    />
                    {errors.location && touched.location && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.location}
                      </div>
                    )}
                  </Col>
                </Col>
                <Col md={12}>
                  <Col className="rowInput">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Phone Number
                    </Text>
                    <Field
                      type="text"
                      name="phone"
                      as={Input}
                      className="w-full h-[44px] mt-0"
                      disabled={isView}
                    />
                    {errors.phone && touched.phone && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.phone}
                      </div>
                    )}
                  </Col>
                </Col>

                <Col md={12}>
                  <Col className="rowInput">
                    <LabelInput
                      title="Created At"
                      value={
                        employeeData?.created_at
                          ? moment(employeeData?.created_at).format(
                              "MMMM Do YYYY",
                            )
                          : "NA"
                      }
                      disabled
                    />
                  </Col>
                </Col>
                <Col md={24}>
                  <div className="rowInput">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Commission Model
                    </Text>
                    <Select
                      name="commission_model_id"
                      options={selectOptions}
                      value={values.commission_model_id}
                      onChange={(value) =>
                        setFieldValue("commission_model_id", value)
                      }
                      className="w-full h-[44px] mt-0"
                      disabled={isView}
                    />
                    {errors.commission_model_id &&
                      touched.commission_model_id && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.commission_model_id}
                        </div>
                      )}
                  </div>
                </Col>
                <Col md={24}>
                  <Col style={{ margin: "12px 6px 6px" }}>
                    <LabelInput
                      title="Last Login"
                      value={
                        employeeData?.token_created_at
                          ? moment(employeeData?.token_created_at).format(
                              "MMMM Do YYYY",
                            )
                          : "NA"
                      }
                      disabled
                    />
                  </Col>
                </Col>
              </Row>
            </div>
            <div className="px-4 py-6 flex gap-3 items-center justify-between">
              <Button
                onClick={() => {
                  setIsOpen(false);
                  setEmployeeData(null);
                }}
                className="w-full h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                className="w-full h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
              >
                {isLoading ? <Spin /> : "Save Changes"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AffiliateModalSection;
