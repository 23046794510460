"use client";
import React, { useState } from "react";
import { Modal, Typography, Input, Button, Spin } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Select } from "antd";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";
import useOpenCommission from "../../hooks/use-commission.tsx";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  userType: Yup.string().required("User Type is required"),
  percentage: Yup.number()
    .required("Percentage is required")
    .min(0, "Percentage must be greater than or equal to 0")
    .max(100, "Percentage must be less than or equal to 100"),
  lifetimeCommission: Yup.string().required("Lifetime Commission is required"),
  setAsDefault: Yup.string(),
});

const EditModal = ({
  isEditOpen,
  setIsEditOpen,
  commissionData,
  setCommissionData,
  refresh,
}) => {
  const { Text } = Typography;
  const { Option } = Select;
  const { getCommission } = useOpenCommission();

  const [isLoading, setIsLoading] = useState(false);
  const handleSave = (values, { resetForm }) => {
    const data = {
      name: values.name,
      percentage: +values.percentage,
      user_type_id:
        values.userType === "Employee"
          ? 3
          : values.userType === "Affiliates"
            ? 1
            : values.userType === "Super Partner"
              ? 4
              : 5,
      is_life_time_comm: values.lifetimeCommission === "Yes" ? true : false,
      is_default: values.setAsDefault === "Yes" ? true : false,
    };

    if (commissionData) {
      axiosClient()
        .patch(`commission-model/${commissionData?.commission_model_id}`, data)
        .then((res) => {
          resetForm();
          setCommissionData();
          setIsEditOpen(false);
          setIsLoading(false);
          toast.success("Commission updated successfully");
          getCommission();
          refresh();
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
          toast.error(err.response.data.message || "Something went wrong");
        });
    } else {
      axiosClient()
        .post("commission-model", data)
        .then((res) => {
          setIsEditOpen(false);
          setIsLoading(false);
          toast.success("Commission created successfully");
          getCommission();
          resetForm();
          refresh();
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
          toast.error("Something went wrong");
        });
    }
  };

  return (
    <div>
      <Modal
        centered
        visible={isEditOpen}
        width={400}
        style={{
          boxShadow: "0px 32px 64px -12px #10182824",
        }}
        footer={null}
        closable={false}
        className="editPopup"
        wrapClassName="custom-modal"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: commissionData?.name,
            userType:
              commissionData?.user_type_id?.user_type_id === 1
                ? "Affiliates"
                : commissionData?.user_type_id?.user_type_id === 3
                  ? "Employee"
                  : commissionData?.user_type_id?.user_type_id === 4
                    ? "Super Partner"
                    : commissionData?.user_type_id?.user_type_id === 5
                      ? "Mini Partner"
                      : "",
            percentage: commissionData?.percentage,
            lifetimeCommission: commissionData?.is_life_time_comm
              ? "Yes"
              : "No",
            setAsDefault: commissionData?.is_default ? "Yes" : "No",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ errors, touched, setFieldValue, resetForm }) => (
            <Form>
              <div className="flex justify-between items-center py-4 px-6 border-b heads">
                <Text className="text-[#101828] text-[18px] font-semibold">
                  Commission Model
                </Text>
                <img
                  src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    setIsEditOpen(false);
                    setCommissionData();
                    resetForm();
                  }}
                />
              </div>
              <div className="py-5 px-6 border-b gap-4">
                <div className={`w-full `}>
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Name
                  </Text>
                  <Field
                    as={Input}
                    name="name"
                    style={{
                      fontSize: "16px",
                      boxShadow: "0px 1px 2px 0px #1018280D",
                      border: "1px solid #D0D5DD",
                      borderRadius: "8px",
                      fontWeight: "400",
                    }}
                    placeholder="Flat 20% for Employee"
                    className={`w-full h-[44px]  placeholder:text-[#475467] text-[#101828] rounded-[8px] mt-2 ${
                      errors.name && touched.name ? "border-red-500" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div className="w-full grid mt-4">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    User Type
                  </Text>
                  <Field
                    as={Select}
                    name="userType"
                    // value={}
                    placeholder="User Type"
                    className={`w-full selectField h-[44px] mt-2 placeholder:text-[#475467] placeholder:font-medium text-[#101828] rounded-[8px] ${
                      errors.userType && touched.userType
                        ? "border-red-500"
                        : ""
                    }`}
                    onChange={(value) => setFieldValue("userType", value)}
                  >
                    <Option value="Employee">Employee</Option>
                    <Option value="Affiliates">Partner</Option>
                    <Option value="Super Partner">Super Partner</Option>
                    <Option value="Mini Partner">Mini Partner</Option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="text-red-500"
                  />

                  <Text className="text-[#344054] text-[14px] font-medium mt-4">
                    Percentage
                  </Text>
                  <div className="relative">
                    <Field
                      as={Input}
                      style={{
                        fontSize: "16px",
                        boxShadow: "0px 1px 2px 0px #1018280D",
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        fontWeight: "400",
                      }}
                      type="text"
                      name="percentage"
                      placeholder="Enter Percentage"
                      className={`w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2 ${
                        errors.percentage && touched.percentage
                          ? "border-red-500"
                          : ""
                      }`}
                      onChange={(event) => {
                        const { value } = event.target;
                        const regex = /^\d*\.?\d*$/;
                        if (regex.test(value)) {
                          setFieldValue("percentage", value);
                        }
                      }}
                    />
                    <div className="absolute right-1 top-3 text-[#667085] flex justify-center items-center bg-white h-[35px] w-[35px]">
                      %
                    </div>
                  </div>
                  <ErrorMessage
                    name="percentage"
                    component="div"
                    className="text-red-500"
                  />

                  <Text className="text-[#344054] text-[14px] mt-4 font-medium">
                    Lifetime Commission
                  </Text>
                  <Field
                    as={Select}
                    name="lifetimeCommission"
                    className={`w-full selectField h-[44px] mt-2 placeholder:text-[#101828] placeholder:font-medium text-[#667085] rounded-[8px] ${
                      errors.lifetimeCommission && touched.lifetimeCommission
                        ? "border-red-500"
                        : ""
                    }`}
                    onChange={(value) =>
                      setFieldValue("lifetimeCommission", value)
                    }
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Field>
                  <ErrorMessage
                    name="lifetimeCommission"
                    component="div"
                    className="text-red-500"
                  />
                  <Text className="text-[#475467] text-[14px] font-normal mt-1">
                    1 year commission if no lifetime commission.
                  </Text>

                  <Text className="text-[#344054] text-[14px] mt-4 font-medium">
                    Set as Default
                  </Text>
                  <Field
                    as={Select}
                    name="setAsDefault"
                    className={`w-full selectField h-[44px] mt-2 placeholder:text-[#101828] placeholder:font-medium text-[#667085] rounded-[8px] ${
                      errors.lifetimeCommission && touched.lifetimeCommission
                        ? "border-red-500"
                        : ""
                    }`}
                    onChange={(value) => setFieldValue("setAsDefault", value)}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Field>
                  <ErrorMessage
                    name="setAsDefault"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="px-4 py-6 flex gap-3 items-center justify-between">
                <Button
                  onClick={() => {
                    setIsEditOpen(false);
                    setCommissionData();
                    resetForm();
                  }}
                  className="w-full h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="primary"
                  htmlType="submit"
                  className="w-full h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                >
                  {isLoading ? <Spin /> : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default EditModal;
