// import axiosClient from "../utils/axios.ts";

// class Organization {
//   async getAllOrganizations(values) {
//     try {
//       const organization = await axiosClient().get(`pipedrive-organisations/all-referred`);

//       return organization.data;
//     } catch (error) {
//       console.log("error =>", error);
//     }
//   }
// }

// export const organizationApi = new Organization();

import axiosClient from "../utils/axios.ts";

type DateRange = {
  from: string;
  to: string;
} | null;

class Organization {
  async getAllOrganizations(dateRange: DateRange = null) {
    try {
      let url = `pipedrive-organisations/all-referred`;
      if (dateRange) {
        url += `?StartDate=${dateRange.from}&EndDate=${dateRange.to}`;
      }
      const organization = await axiosClient().get(url);
      return organization.data;
    } catch (error) {
      console.error("Error fetching organizations:", error);
      throw error;
    }
  }
}

export const organizationApi = new Organization();
