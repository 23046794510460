// import { useState } from "react";
// import { organizationApi } from "../services/organization.ts";
// import { useDispatch } from "react-redux";
// import { addOpenOrganizations } from "../redux/organization.ts";
// import { setReduxLoading } from "../redux/loading.ts";

// type InitialValues = {
//   values?: any;
// };

// const useOpenOrganization = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const dispatch = useDispatch();
//   const getOrganizations = async (values: InitialValues) => {
//     dispatch(setReduxLoading(true));
//     try {
//       console.log("Dates in API Call: ", values);
//       const jobs = await organizationApi.getAllOrganizations(values);
//       dispatch(addOpenOrganizations(jobs));

//       setIsLoading(false);
//     } catch (error) {
//       setIsLoading(false);
//     }
//     dispatch(setReduxLoading(false));
//   };

//   return {
//     isLoading,
//     getOrganizations,
//   };
// };

// export default useOpenOrganization;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { addOpenOrganizations } from "../redux/organization.ts";
import { setReduxLoading } from "../redux/loading.ts";
import { organizationApi } from "../services/organization.ts";

type DateRange = {
  from: string;
  to: string;
} | null;

const useOpenOrganization = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getOrganizations = async (dateRange: DateRange = null) => {
    dispatch(setReduxLoading(true));
    try {
      const jobs = await organizationApi.getAllOrganizations(dateRange);
      dispatch(addOpenOrganizations(jobs));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getOrganizations,
  };
};

export default useOpenOrganization;
