import React, { useEffect, useState } from "react";
import { Select, Space, Image } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface SuperPartner {
  user_id: number;
  name: string;
  email: string;
  profile_image: string | null;
  role: string;
}

interface PartnerDropdownProps {
  linkedSuperPartners: SuperPartner[];
  onChange: (selectedPartners: number[]) => void;
}

const PartnerDropdown: React.FC<PartnerDropdownProps> = ({
  linkedSuperPartners,
  onChange,
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const superPartners = useSelector(
    (state: RootState) => state.teams.superPartners,
  );

  useEffect(() => {
    // Set initially selected items based on linkedSuperPartners
    setSelectedItems(linkedSuperPartners?.map((partner) => partner.user_id));
  }, [linkedSuperPartners]);

  const formatUserData = (inputData: SuperPartner[]) => {
    return inputData?.map((user) => ({
      label: user.name,
      value: user.user_id,
      image: user.profile_image || "/images/profile.svg",
    }));
  };

  const superPartnerOptions = formatUserData(superPartners);

  const handleChange = (value: number[]) => {
    setSelectedItems(value);
    onChange(value);
  };

  return (
    <>
      <label>Super Partners</label>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Select super partners"
        value={selectedItems}
        onChange={handleChange}
        optionLabelProp="label"
        optionFilterProp="label"
      >
        {superPartnerOptions?.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.label}
          >
            <Space>
              <Image
                src={option?.image}
                alt={option?.label}
                width={24}
                height={24}
                style={{ borderRadius: "50%" }}
                preview={false}
              />
              {option.label}
            </Space>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default PartnerDropdown;

//Disabled Removing Super Partner version
// import React, { useEffect, useState } from 'react';
// import { Select, Space, Image } from 'antd';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../redux/store';

// interface SuperPartner {
//   user_id: number;
//   name: string;
//   email: string;
//   profile_image: string | null;
//   role: string;
// }

// interface PartnerDropdownProps {
//   linkedSuperPartners: SuperPartner[];
//   onChange: (selectedPartners: number[]) => void;
// }

// const PartnerDropdown: React.FC<PartnerDropdownProps> = ({ linkedSuperPartners, onChange }) => {
//   const [selectedItems, setSelectedItems] = useState<number[]>([]);
//   const superPartners = useSelector((state: RootState) => state.teams.superPartners);

//   useEffect(() => {
//     // Set initially selected items based on linkedSuperPartners
//     setSelectedItems(linkedSuperPartners?.map(partner => partner.user_id));
//   }, [linkedSuperPartners]);

//   const formatUserData = (inputData: SuperPartner[]) => {
//     return inputData?.map(user => ({
//       label: user.name,
//       value: user.user_id,
//       image: user.profile_image || '/images/profile.svg'
//     }));
//   };

//   const superPartnerOptions = formatUserData(superPartners);

//   const handleChange = (value: number[]) => {
//     // Ensure all linked super partners remain selected
//     const newSelectedItems = Array.from(new Set([...linkedSuperPartners.map(p => p.user_id), ...value]));
//     setSelectedItems(newSelectedItems);
//     onChange(newSelectedItems);
//   };

//   const filterOption = (input: string, option: { label: string }) =>
//     (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

//   return (
//     <>
//       <label>Super Partners</label>
//       <Select
//         mode="multiple"
//         style={{ width: '100%' }}
//         placeholder="Select super partners"
//         value={selectedItems}
//         onChange={handleChange}
//         optionLabelProp="label"
//         optionFilterProp="label"
//         filterOption={filterOption}
//       >
//         {superPartnerOptions?.map(option => (
//           <Select.Option
//             key={option.value}
//             value={option.value}
//             label={option.label}
//             disabled={linkedSuperPartners.some(p => p.user_id === option.value)}
//           >
//             <Space>
//               <Image
//                 src={option?.image}
//                 alt={option?.label}
//                 width={24}
//                 height={24}
//                 style={{ borderRadius: '50%' }}
//                 preview={false}
//               />
//               {option.label}
//             </Space>
//           </Select.Option>
//         ))}
//       </Select>
//     </>
//   );
// };

// export default PartnerDropdown;
