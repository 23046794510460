import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Col, Row } from "antd";
import SelectModalDropdown from "./component/transaction-select/index.tsx";
import LabelInput from "../../../../../../components/commissionLabelInput.tsx";
import StaticDatePickerLandscape from "./component/datepicker/index.tsx";
import FilledButton from "../../../../../../components/filledButton.tsx";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import axiosClient from "../../../../../../utils/axios.ts";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({
  open,
  handleClose,
  refresh,
  isAffiliate,
}) {
  const [allCompanies, setAllCompanies] = useState([]);

  const getAllCompaanies = async () => {
    await axiosClient()
      .get("pipedrive-organisations")
      .then((res) => {
        if (res.data.length != 0) {
          setAllCompanies(res.data);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAllCompaanies();
  }, []);
  const allAffiliates = useSelector(
    (state: RootState) => state.teams.affilliate,
  );
  const allEmployees = useSelector((state: RootState) => state.teams.employee);

  const [dateValue, setDateValue] = React.useState<string | null>(null);
  const [show, setShow] = React.useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [selectedRevenueType, setSelectedRevenueType] = React.useState("");
  const [revenueAmount, setRevenueAmount] = React.useState("");
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [isFormValid, setIsFormValid] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (selectedAffiliate) {
      if (isAffiliate) {
        const filtered = allCompanies.filter(
          (company) => company.AffiliateID === selectedAffiliate,
        );
        setFilteredCompanies(filtered);
      } else {
        const selectedEmployee = allEmployees.find(
          (employee) => employee.user_id.toString() === selectedAffiliate,
        );
        const filtered = allCompanies.filter(
          (company) => company.Owner?.email === selectedEmployee?.email,
        );
        setFilteredCompanies(filtered);
      }
      setSelectedCompany(""); // Reset selected company when affiliate/employee changes
    } else {
      setFilteredCompanies(allCompanies);
    }
  }, [selectedAffiliate, allCompanies, allEmployees, isAffiliate]);

  const affiliateOptions = allAffiliates.map((affiliate) => ({
    label: affiliate.name,
    value: affiliate.user_id.toString(),
  }));

  const employeeOptions = allEmployees.map((employee) => ({
    label: employee.name,
    value: employee.user_id.toString(),
  }));

  const companyOptions = filteredCompanies.map((company) => ({
    label: company.Name,
    value: company.ID.toString(),
  }));

  const revenueOptions = [
    { label: "Service fee", value: "service_fee" },
    { label: "Research fee", value: "research_fee" },
    { label: "Markup fee", value: "markup_fee" },
    { label: "Subscription fee", value: "subscription_fee" },
  ];

  const handleAffiliateOrEmployeeChange = (value) => {
    setSelectedAffiliate(value);
    if (isAffiliate) {
      const filtered = allCompanies.filter(
        (company) => company.AffiliateID === value,
      );
      setFilteredCompanies(filtered);
    } else {
      const selectedEmployee = allEmployees.find(
        (employee) => employee.user_id.toString() === value,
      );
      const filtered = allCompanies.filter(
        (company) => company.Owner?.email === selectedEmployee?.email,
      );
      setFilteredCompanies(filtered);
    }
    setSelectedCompany(""); // Reset selected company when affiliate/employee changes
  };

  const handleDateChange = (date: string | null) => {
    setDateValue(date);
    setShow(false);
  };

  React.useEffect(() => {
    // Check if all required fields are filled
    const isValid =
      selectedAffiliate !== "" &&
      selectedCompany !== "" &&
      selectedRevenueType !== "" &&
      revenueAmount !== "" &&
      dateValue !== null;

    setIsFormValid(isValid);
  }, [
    selectedAffiliate,
    selectedCompany,
    selectedRevenueType,
    revenueAmount,
    dateValue,
  ]);

  const handleSubmit = () => {
    if (!isFormValid) return;

    const selectedCompanyData = allCompanies.find(
      (company) => company.ID.toString() === selectedCompany,
    );

    let payload;

    if (isAffiliate) {
      const selectedAffiliateData = allAffiliates.find(
        (affiliate) => affiliate.user_id.toString() === selectedAffiliate,
      );

      payload = {
        fee: parseFloat(revenueAmount),
        createdAt: new Date().toISOString(),
        dealName: selectedCompanyData?.Name
          ? `${selectedCompanyData?.Name} Lead`
          : "",
        accountName: selectedCompanyData?.Name || "",
        date: dateValue ? moment(dateValue).toISOString() : null,
        feeType: selectedRevenueType,
        affiliateId: selectedAffiliate,
        affiliateName: selectedAffiliateData?.name || "",
        organizationId: selectedCompany,
        ownerEmail: selectedCompanyData?.Owner?.email || "",
        ownerName: selectedCompanyData?.Owner?.name || "",
        sdrOwnerName: selectedCompanyData?.SDROwner?.name || "",
        sdrOwnerEmail: selectedCompanyData?.SDROwner?.email || "",
      };
    } else {
      const selectedEmployeeData = allEmployees.find(
        (employee) => employee.user_id.toString() === selectedAffiliate,
      );

      payload = {
        fee: parseFloat(revenueAmount),
        createdAt: new Date().toISOString(),
        dealName: selectedCompanyData?.Name
          ? `${selectedCompanyData?.Name} Lead`
          : "",
        accountName: selectedCompanyData?.Name || "",
        date: dateValue ? moment(dateValue).toISOString() : null,
        feeType: selectedRevenueType,
        affiliateId: null,
        affiliateName: null,
        organizationId: selectedCompany,
        ownerEmail: selectedEmployeeData?.email || "",
        ownerName: selectedEmployeeData?.name || "",
        sdrOwnerName: selectedCompanyData?.SDROwner?.name || "",
        sdrOwnerEmail: selectedCompanyData?.SDROwner?.email || "",
      };
    }

    axiosClient()
      .post("transactions", payload)
      .then((res) => {
        toast.success("Transaction Created!");
        // Clear the state
        setSelectedAffiliate("");
        setSelectedCompany("");
        setSelectedRevenueType("");
        setRevenueAmount("");
        setDateValue(null);

        // Close the modal
        handleClose();
        refresh();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || "An error occurred");
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} className="modalView" style={{ position: "relative" }}>
        <h1
          onClick={handleClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "23px",
            top: "18px",
            fontSize: "34px",
            zIndex: "9",
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm75.31 260.69a16 16 0 1 1-22.62 22.62L256 278.63l-52.69 52.68a16 16 0 0 1-22.62-22.62L233.37 256l-52.68-52.69a16 16 0 0 1 22.62-22.62L256 233.37l52.69-52.68a16 16 0 0 1 22.62 22.62L278.63 256z"></path>
          </svg>
        </h1>
        <Col className="modalHead">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
            >
              <circle
                cx="48"
                cy="48"
                r="48"
                fill="url(#paint0_linear_1104_32331)"
              />
              <path
                d="M68.2484 47.1729V39.7626C68.2484 34.268 64.8268 30.3867 59.3278 30.3867H36.6706C31.1869 30.3867 27.75 34.268 27.75 39.7626V54.5828C27.75 60.0775 31.1716 63.9588 36.6706 63.9588H44.1552"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M27.75 42.3096H68.2484"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M64.3411 65.6096V53.0879"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M68.2493 61.834L64.342 65.6036L60.4321 61.834"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M53.9946 51.9727V64.4944"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M50.0852 55.7423L53.9928 51.9727L57.9024 55.7423"
                stroke="black"
                strokeWidth="3.375"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1104_32331"
                  x1="48"
                  y1="0"
                  x2="48"
                  y2="96"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E0E0E0" />
                  <stop offset="1" stopColor="#FAFAFA" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <h1>Manually Add Transaction</h1>
        </Col>
        <Row>
          <Col md={8}>
            <Col className="dropdownModal">
              <SelectModalDropdown
                options={isAffiliate ? affiliateOptions : employeeOptions}
                title={`${
                  isAffiliate ? "Choose Affiliate" : "Choose Employee"
                }`}
                value={selectedAffiliate}
                onChange={handleAffiliateOrEmployeeChange}
              />
            </Col>
          </Col>
          <Col md={8}>
            <Col className="dropdownModal middle">
              <SelectModalDropdown
                options={companyOptions}
                title="Choose Company"
                value={selectedCompany}
                onChange={setSelectedCompany}
              />
            </Col>
          </Col>
          <Col md={8}>
            <Col className="dropdownModal">
              <SelectModalDropdown
                options={revenueOptions}
                title="Revenue Type"
                value={selectedRevenueType}
                onChange={setSelectedRevenueType}
              />
            </Col>
          </Col>
          <Col md={8} style={{ marginTop: "15px" }}>
            <Col className="dropdownModal input">
              <LabelInput
                title="Revenue amount"
                value={revenueAmount}
                onChange={(e) => setRevenueAmount(e.target.value)}
              />
            </Col>
          </Col>
          <Col md={8} style={{ marginTop: "15px" }}>
            <Col className="input datepicker">
              <label>Choose Transaction Date</label>

              <StaticDatePickerLandscape onChange={handleDateChange} />
            </Col>
          </Col>
          <Col md={8}></Col>
          <Col>
            <FilledButton
              className="filledBtn"
              disabled={!isFormValid}
              onClick={handleSubmit}
            >
              Submit
            </FilledButton>
          </Col>
        </Row>
      </Box>
    </Modal>
  );
}
