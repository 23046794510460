import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FilledButton from "../../../../../components/filledButton.tsx";
import LabelInput from "../../../../../components/commissionLabelInput.tsx";
import FilterIcon from "../../../../../assets/images/filter.png";
import DateIcon from "../../../../../assets/images/date.png";
import CloseIcon from "../../../../../assets/images/close.png";
import SelectDropdown from "../../filters/component/select-dropdown/index.tsx";
import axiosClient from "../../../../../utils/axios.ts";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import { Button, DatePicker, Input, TimeRangePickerProps } from "antd";

interface Filters {
  companyName: string;
  userType: string;
  userName: string;
  revenueType: string;
  minAmount: string;
  maxAmount: string;
}

interface DateRange {
  startDate: string;
  endDate: string;
}

export default function ArchivedFilterComponent({
  updateFilters,
}: {
  updateFilters: (filters: Filters & DateRange) => void;
}) {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const ref = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState<Filters>({
    companyName: "",
    userType: "",
    userName: "",
    revenueType: "",
    minAmount: "",
    maxAmount: "",
  });

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: "",
    endDate: "",
  });

  const [appliedFilters, setAppliedFilters] = useState<Filters & DateRange>({
    ...filters,
    ...dateRange,
  });

  const [values, setValues] = useState({ from: "", to: "" });
  const { RangePicker } = DatePicker;

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
    {
      label: "Yesterday",
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    { label: "All Time", value: [dayjs("2021-12-31"), dayjs()] },
  ];

  const RangePickerFooter = ({
    onOk,
    onCancel,
  }: {
    onOk: () => void;
    onCancel: () => void;
  }) => (
    <div className="w-full py-4">
      <div className="flex justify-between mb-4">
        <Input
          value={values.from ? moment(values.from).format("DD/MM/YYYY") : ""}
          placeholder="Start Date"
          readOnly
          className="w-[45%]"
        />
        <Input
          value={values.to ? moment(values.to).format("DD/MM/YYYY") : ""}
          placeholder="End Date"
          readOnly
          className="w-[45%]"
        />
      </div>
      <div className="w-full flex justify-end gap-3 py-4 items-center">
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="rounded-[8px] h-[40px]"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="bg-[#155EEF] rounded-[8px] h-[40px]"
          type="primary"
          onClick={onOk}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    if (dates) {
      setValues({
        from: dateStrings[0],
        to: dateStrings[1],
      });
    } else {
      console.log("Clear");
    }
  };

  const Customimage = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(18).svg?updatedAt=1709195817408"
        alt=""
      />
    );
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
      setDate(false);
      setValues({ from: "", to: "" }); // Discard selected dates when closing date picker
    }
  };

  useEffect(() => {
    axiosClient()
      .get("pipedrive-organisations")
      .then((res) => setAllCompanies(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    axiosClient()
      .get("users")
      .then((res) =>
        setAllUsers(res.data.filter((user) => user.role !== "ADMIN")),
      )
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const companyOptions = [
    ...new Set(allCompanies?.map((org: any) => org.Name)),
  ].map((name) => ({ label: name, value: name }));

  const userTypeOptions = [
    { value: "EMPLOYEE", label: "Employee" },
    { value: "AFFILIATES", label: "Partner" },
    { value: "SUPER_PARTNER", label: "Super Partner" },
    { value: "MINI_PARTNER", label: "Mini Partner" },
  ];

  const getUserOptions = () => {
    if (!filters.userType) {
      return allUsers.map((user) => ({ value: user.name, label: user.name }));
    }
    return allUsers
      .filter((user) => user.role === filters.userType)
      .map((user) => ({ value: user.name, label: user.name }));
  };

  const revenueTypeOptions = [
    { value: "markup_fee", label: "Markup Fee" },
    { value: "research_fee", label: "Research Fee" },
    { value: "service_fee", label: "Service Fee" },
    { value: "subscription_fee", label: "Subscription Fee" },
  ];

  const handleFilterChange = (filterType: keyof Filters, value: string) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [filterType]: value };
      if (filterType === "userType") {
        newFilters.userName = "";
      }
      return newFilters;
    });
  };

  const handleReset = () => {
    setFilters({
      companyName: "",
      userType: "",
      userName: "",
      revenueType: "",
      minAmount: "",
      maxAmount: "",
    });
    setAppliedFilters((prev) => ({
      ...prev,
      ...filters,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    }));
    updateFilters({
      ...filters,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  };

  const handleApply = () => {
    const newFilters = { ...filters };
    if (values.from && values.to) {
      setDateRange({
        startDate: values.from,
        endDate: values.to,
      });
    }
    setAppliedFilters({
      ...newFilters,
      startDate: values.from || dateRange.startDate,
      endDate: values.to || dateRange.endDate,
    });
    updateFilters({
      ...newFilters,
      startDate: values.from || dateRange.startDate,
      endDate: values.to || dateRange.endDate,
    });
    setShow(false);
  };

  const removeFilter = (key: keyof (Filters & DateRange)) => {
    if (key === "startDate" || key === "endDate") {
      setDateRange({ startDate: "", endDate: "" });
      setValues({ from: "", to: "" });
      setAppliedFilters((prev) => ({ ...prev, startDate: "", endDate: "" }));
      updateFilters({ ...appliedFilters, startDate: "", endDate: "" });
    } else {
      setFilters((prev) => ({ ...prev, [key]: "" }));
      setAppliedFilters((prev) => ({ ...prev, [key]: "" }));
      updateFilters({ ...appliedFilters, [key]: "" });
    }
  };

  const removeDateRange = () => {
    setDateRange({ startDate: "", endDate: "" });
    setValues({ from: "", to: "" });
    setAppliedFilters((prev) => ({ ...prev, startDate: "", endDate: "" }));
    updateFilters({ ...appliedFilters, startDate: "", endDate: "" });
  };

  const getLabelForValue = (options, value) => {
    const option = options.find((opt) => opt.value === value);
    return option ? option.label : "";
  };

  return (
    <>
      <Box className="filterBox" style={{ position: "relative" }}>
        <Box className="filterOption">
          <FilledButton
            className="btnDate"
            onClick={() => {
              setShow(true);
              setDate(false); // Close date picker when opening filter
            }}
          >
            <img src={FilterIcon} alt="" /> Filters
          </FilledButton>
          {show && (
            <Box className="filterSection" ref={ref}>
              <Typography variant="h1">Filters</Typography>
              <Box className="filterMenu">
                <Grid
                  container
                  sx={{ width: "352px !important", margin: "0 auto" }}
                >
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Min amount"
                        type="number"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={filters.minAmount}
                        onChange={(e) =>
                          handleFilterChange("minAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={0.6}></Grid>
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Max amount"
                        type="number"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={filters.maxAmount}
                        onChange={(e) =>
                          handleFilterChange("maxAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Company name"
                        options={companyOptions}
                        value={filters.companyName}
                        onChange={(value) =>
                          handleFilterChange("companyName", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="User Type"
                        options={userTypeOptions}
                        value={filters.userType}
                        onChange={(value) =>
                          handleFilterChange("userType", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="User Name"
                        options={getUserOptions()}
                        value={filters.userName}
                        onChange={(value) =>
                          handleFilterChange("userName", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Revenue Type"
                        options={revenueTypeOptions}
                        value={filters.revenueType}
                        onChange={(value) =>
                          handleFilterChange("revenueType", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                      width: "100%",
                    }}
                  >
                    <FilledButton className="cancelBtn" onClick={handleReset}>
                      Reset
                    </FilledButton>
                    <FilledButton
                      style={{ background: "#155EEF", color: "#fff" }}
                      className="cancelBtn"
                      onClick={handleApply}
                    >
                      Apply
                    </FilledButton>
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="filterOption" sx={{ position: "relative" }}>
          {!date && (
            <FilledButton
              className="btnDate sz"
              onClick={() => {
                setDate(true);
                setShow(false); // Close filter popup when opening date picker
              }}
            >
              <img src={DateIcon} alt="" /> Select Dates
            </FilledButton>
          )}
          {date && (
            <Box>
              <RangePicker
                open={date}
                popupClassName="custom-calendar-class yearPicker"
                presets={rangePresets}
                onChange={onRangeChange}
                suffixIcon={<Customimage />}
                className="border border-[#D0D5DD] h-[40px]"
                renderExtraFooter={() => (
                  <RangePickerFooter
                    onOk={() => {
                      setDate(false);
                      handleApply();
                    }}
                    onCancel={() => {
                      setDate(false);
                      setValues({ from: "", to: "" });
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {Object.entries(appliedFilters).map(([key, value]) => {
            if (!value || key === "startDate" || key === "endDate") return null;
            let displayValue = value;
            if (key === "userType") {
              displayValue = getLabelForValue(userTypeOptions, value);
            } else if (key === "companyName") {
              displayValue = getLabelForValue(companyOptions, value);
            } else if (key === "userName") {
              displayValue = getLabelForValue(getUserOptions(), value);
            } else if (key === "revenueType") {
              displayValue = getLabelForValue(revenueTypeOptions, value);
            }
            return (
              <FilledButton
                key={key}
                className="tagBtn"
                onClick={() => removeFilter(key as keyof (Filters & DateRange))}
              >
                <img src={CloseIcon} alt="" />
                {`${key}: ${displayValue}`}
              </FilledButton>
            );
          })}
          {appliedFilters.startDate && appliedFilters.endDate && (
            <FilledButton className="tagBtn" onClick={removeDateRange}>
              <img src={CloseIcon} alt="" />
              {`Date: ${moment(appliedFilters.startDate).format("DD/MM/YYYY")} - ${moment(appliedFilters.endDate).format("DD/MM/YYYY")}`}
            </FilledButton>
          )}
        </Box>
      </Box>
    </>
  );
}
