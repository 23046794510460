// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Table } from "../../components/index.ts";
import SearchSection from "./search-section.tsx";
import Close from "../../../src/assets/images/closed.png";
import SidebarFilter from "./filter.tsx";
import EditModal from "./edit-modal.tsx";
import { PAYOUTS_DATA_COLUMN } from "../../constants/data.ts";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import axiosClient from "../../utils/axios.ts";
import useOpenPayouts from "../../hooks/use-payouts.tsx";
import { Spin, Modal, Table as AntTable } from "antd"; // Import Ant Design Table
import PayoutTable from "./payoutTable.tsx";

type Values = {
  from: string;
  to: string;
};

const Main = () => {
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedObject, setSelectedObject] = useState<any>({});
  const [isLoad, setIsLoad] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState<any>({});
  const [tempCheckboxValues, setTempCheckboxValues] =
    useState<any>(checkboxValues);
  const [sidebarData, setSidebarData] = useState<any>();
  const [isCheck, setIsCheck] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [payouts, setPayouts] = useState([]);
  const [payoutData, setPayoutData] = useState([]);
  const { getPayouts } = useOpenPayouts();

  const initialState: Values = { from: "", to: "" };

  const [values, setValues] = useState<Values | undefined>(initialState);
  const loading = useSelector((state: RootState) => state.isLoading.isLoading);

  const handleEditAmount = (selectedKeys, newAmount) => {
    const numericAmount = parseFloat(newAmount);

    setIsLoad(true);
    axiosClient()
      .patch(`users-commissions/${selectedObject?.user_commission_id}`, {
        commission_paid: numericAmount,
        is_approved: true,
        payment_status: activeTab === 0 ? "unpaid" : "paid manual",
      })
      .then(() => {
        getPayouts();
        setIsLoad(false);
        setIsEditOpen(false);
        toast.success("Approved and updated successfully");
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoad(false);
      });
  };

  useEffect(() => {
    setPayoutData(payouts);
  }, []);

  useEffect(() => {
    setPayoutData(payouts);
    setSidebarData(payouts);
  }, [payouts]);

  // Function to build query parameters from both filter states
  const buildQueryParams = () => {
    const queryParams: any = [];

    // Add date parameters if they exist
    if (values?.from) {
      queryParams.push(`StartDate=${values.from}`);
    }
    if (values?.to) {
      queryParams.push(`EndDate=${values.to}`);
    }

    // Add filter parameters if they exist
    if (checkboxValues.Status?.length) {
      const statusJson = JSON.stringify(checkboxValues.Status);
      queryParams.push(`payment_status=${encodeURIComponent(statusJson)}`);
    }

    if (checkboxValues.Role?.length) {
      const roleJson = JSON.stringify(checkboxValues.Role);
      queryParams.push(`Role=${encodeURIComponent(roleJson)}`);
    }

    if (checkboxValues.Commission?.length) {
      const [minCommission, maxCommission] = checkboxValues.Commission;
      queryParams.push(
        `MinCommission=${encodeURIComponent(JSON.stringify(minCommission))}`,
      );
      queryParams.push(
        `MaxCommission=${encodeURIComponent(JSON.stringify(maxCommission))}`,
      );
    }

    return queryParams.length ? `?${queryParams.join("&")}` : "";
  };

  // Function to fetch data with all current filters
  const fetchFilteredData = async () => {
    setIsLoad(true);
    try {
      const queryString = buildQueryParams();
      const response = await axiosClient().get(
        `users-commissions/formatted-data/${queryString}`,
      );
      setPayouts(response.data);
      setPayoutData(response.data);
      setIsLoad(false);
      setIsCheck(true);
    } catch (err) {
      console.error("Error fetching filtered data:", err);
      toast.error("Something went wrong!");
      setIsLoad(false);
      setIsCheck(false);
    }
  };

  // Effect to watch both filter states and update data
  useEffect(() => {
    fetchFilteredData();
  }, [values, checkboxValues, isSubmit]);

  return (
    <>
      <div>
        <SearchSection
          setIsFilterOpen={setIsFilterOpen}
          setPayoutData={setPayoutData}
          data={payouts}
          setIsCheck={setIsCheck}
          checkboxValues={checkboxValues}
          isCheck={isCheck}
          setCheckboxValues={setCheckboxValues}
          setIsSubmit={setIsSubmit}
          values={values}
          setValues={setValues}
          setTempCheckboxValues={setTempCheckboxValues}
        />
        {loading || isLoad ? (
          <div className="flex items-center justify-center my-10">
            <Spin fullscreen size="large" />
          </div>
        ) : (
          <div style={{ marginTop: "24px" }}>
            <Table
              setIsViewOpen={setIsViewOpen}
              activeTab={activeTab}
              setIsEditOpen={setIsEditOpen}
              column={PAYOUTS_DATA_COLUMN}
              data={payouts}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedRowKeys={selectedRowKeys}
              setPayoutData={setPayoutData}
              payoutData={payoutData}
              setSelectedObject={setSelectedObject}
              selectedObject={selectedObject}
              setActiveTab={setActiveTab}
              refresh={fetchFilteredData}
            />
          </div>
        )}

        {isViewOpen && (
          <Modal
            className="tableModal modalclass"
            width={1080}
            open={isViewOpen}
            onCancel={() => setIsViewOpen(false)}
            footer={null}
          >
            <div>
              <img
                src={Close}
                alt=""
                onClick={() => setIsViewOpen(false)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "20px",
                  top: "31px",
                }}
              />
              <PayoutTable data={selectedObject} />
            </div>
          </Modal>
        )}

        <SidebarFilter
          isViewOpen={isFilterOpen}
          setIsViewOpen={setIsFilterOpen}
          setPayoutData={setPayoutData}
          sidebarData={sidebarData}
          setCheckboxValues={setCheckboxValues}
          checkboxValues={checkboxValues}
          payouts={payouts}
          tempCheckboxValues={tempCheckboxValues}
          setTempCheckboxValues={setTempCheckboxValues}
        />

        <EditModal
          setIsEditOpen={setIsEditOpen}
          isEditOpen={isEditOpen}
          selectedRowKeys={selectedRowKeys}
          handleEditAmount={handleEditAmount}
          selectedObject={selectedObject}
        />
      </div>
    </>
  );
};

export default Main;
