// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Drawer, Typography, Input, Button, Spin } from "antd";
import { Checkbox, Slider } from "../../components/index.ts";
import {
  FILTER_NAME,
  FILTER_TYPE,
  FILTER_STATUS,
  FILTER_COMPANY_STATUS,
} from "../../constants/data.ts";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";

const SidebarFilter = ({
  isViewOpen,
  setIsViewOpen,
  sidebarData,
  isCompany,
  organization,
  setCheckboxValues,
  checkboxValues,
  setIsCheck,
  isLoading,
  onReset,
}) => {
  const { Text } = Typography;
  const [itemsToShow, setItemsToShow] = useState(10);
  const [searchName, setSearchName] = useState(sidebarData);
  const [searchValue, setSearchValue] = useState(null);
  const [tempCheckboxValues, setTempCheckboxValues] = useState({});

  useEffect(() => setTempCheckboxValues(checkboxValues), [checkboxValues]);

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const handleCheckboxChange = (name, value) => {
    setTempCheckboxValues((prevValues) => {
      // Special handling for slider values
      if (name === "commission" || name === "revenue") {
        return {
          ...prevValues,
          [name]: value, // Directly set the value for sliders (which should be an array of [min, max])
        };
      }

      // Checkbox handling logic
      const currentValues = prevValues[name] || [];
      if (currentValues.includes(value)) {
        return {
          ...prevValues,
          [name]: currentValues.filter((v) => v !== value),
        };
      } else {
        return {
          ...prevValues,
          [name]: [...currentValues, value],
        };
      }
    });
  };

  const handleShowMore = () => {
    setItemsToShow((prevItemsToShow) => prevItemsToShow + 10);
  };

  const handleShowLess = () => {
    setItemsToShow((prevItemsToShow) => Math.max(10, prevItemsToShow - 10));
  };

  const HandleNameSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = FILTER_NAME;
    } else {
      filteredData = searchName?.filter((x) => {
        return (
          x?.label?.toLowerCase().charAt(0) === value?.toLowerCase().charAt(0)
        );
      });
    }
    setSearchName(filteredData);
  };

  const handleSearch = (value) => {
    let updatedData; // Initialize with the original data

    setSearchValue(value);
    if (!value) {
      setSearchName(sidebarData);
    } else {
      updatedData = sidebarData?.filter((sidebarData) =>
        sidebarData?.Name.toLowerCase()?.includes(value.toLowerCase()),
      );

      setSearchName(updatedData);
    }
  };

  const getUniqueSDROwners = () => {
    const uniqueSDROwnerMap = {};

    organization?.forEach((item) => {
      if (item.SDROwner && item.SDROwner.name) {
        uniqueSDROwnerMap[item.SDROwner.name] = item;
      }
    });

    return Object.values(uniqueSDROwnerMap);
  };

  const getUniqueAffiliates = () => {
    const uniqueAffiliateMap = {};

    organization?.forEach((item) => {
      if (item.AffiliateName && item.AffiliateName !== "") {
        uniqueAffiliateMap[item.AffiliateName] = item;
      }
    });

    return Object.values(uniqueAffiliateMap);
  };

  const getUniqueOwners = () => {
    const uniqueOwnerMap = {};

    organization?.forEach((item) => {
      if (item.Owner && item.Owner.name) {
        uniqueOwnerMap[item.Owner.name] = item;
      }
    });

    return Object.values(uniqueOwnerMap);
  };

  const uniqueSDROwners = getUniqueSDROwners();
  const uniqueAffiliates = getUniqueAffiliates();
  const uniqueOwners = getUniqueOwners();

  useEffect(() => {
    setSearchName(sidebarData);
  }, [sidebarData]);

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setIsViewOpen(false)}
        visible={isViewOpen}
        bodyStyle={{ padding: "20px", marginBottom: "80px" }}
        className="custom-drawer"
      >
        <div className="w-full h-full relative ">
          <div className="flex justify-between items-center">
            <Text className="text-[#101828] text-[20px] font-semibold">
              Filters
            </Text>

            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
              alt=""
              className="w-[15px] cursor-pointer"
              onClick={() => setIsViewOpen(false)}
            />
          </div>
          <Text className="text-[#475467] text-[14px] font-normal">
            Apply filters to your payouts
          </Text>

          {/* Status Filter */}
          {isCompany && (
            <div>
              <div className="flex items-center gap-3 mt-4">
                <Text className="text-[#344054] text-[16px] font-medium">
                  Status
                </Text>
              </div>

              <div className="grid gap-y-4 mt-4 pl-2">
                {isCompany ? (
                  <>
                    {FILTER_COMPANY_STATUS?.map((item, i) => (
                      <Checkbox
                        key={i}
                        data={item}
                        onChange={(value) =>
                          handleCheckboxChange(`status`, value)
                        }
                        checked={tempCheckboxValues?.status?.includes(
                          item?.Name,
                        )}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {FILTER_STATUS?.map((item, i) => (
                      <Checkbox
                        key={i}
                        data={item}
                        onChange={(value) =>
                          handleCheckboxChange(`status`, value)
                        }
                        checked={tempCheckboxValues?.status?.includes(
                          item?.Name,
                        )}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          )}

          {/* Company Filter */}
          <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                Company
              </Text>
            </div>

            <div className="w-full">
              <Input
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                prefix={<SearchIcon />}
                className="w-full h-[44px] mt-3 placeholder:text-black rounded-[8px]"
              />
            </div>
            <div className="grid gap-y-3 mt-4 mb-2 pl-2">
              {searchName
                ?.slice(0, itemsToShow)
                ?.map((item, i) => (
                  <Checkbox
                    key={i}
                    data={item}
                    onChange={(value) => handleCheckboxChange("company", value)}
                    checked={tempCheckboxValues?.company?.includes(item?.Name)}
                  />
                ))}
              <div>
                {itemsToShow < sidebarData?.length && (
                  <button
                    onClick={handleShowMore}
                    className="text-[#004EEB] font-semibold"
                  >
                    Show More &emsp;
                  </button>
                )}

                {itemsToShow > 10 && (
                  <button
                    onClick={handleShowLess}
                    className="text-[#004EEB] font-semibold"
                  >
                    Show Less
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Name Filter */}
          {!isCompany ? (
            <>
              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Name
                  </Text>
                </div>

                <div className="w-full">
                  <Input
                    style={{
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                    placeholder="Search"
                    onChange={(e) => HandleNameSearch(e.target.value)}
                    prefix={<SearchIcon />}
                    className="w-full h-[44px] placeholder:text-black rounded-[8px] mt-3"
                  />
                </div>
                <div className="grid gap-y-3 mt-4 pl-2">
                  {searchName?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item}
                      onChange={(value) => handleCheckboxChange(`name`, value)}
                    />
                  ))}
                </div>
              </div>

              {/* Type Filter */}
              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Type
                  </Text>
                </div>

                <div className="grid gap-y-3 mt-4 pl-2">
                  {FILTER_TYPE?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item}
                      onChange={(value) => handleCheckboxChange(`type`, value)}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Referred by:
                  </Text>
                </div>

                <div className="grid gap-y-3 mt-4 pl-2">
                  {uniqueAffiliates?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item?.AffiliateName}
                      onChange={(value) =>
                        handleCheckboxChange(`referred`, value)
                      }
                      checked={tempCheckboxValues?.referred?.includes(
                        item?.AffiliateName,
                      )}
                    />
                  ))}
                </div>
              </div>

              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Closed by:
                  </Text>
                </div>

                <div className="grid gap-y-3 mt-4 pl-2">
                  {uniqueOwners?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item?.Owner}
                      onChange={(value) =>
                        handleCheckboxChange(`closed`, value)
                      }
                      checked={tempCheckboxValues?.closed?.includes(
                        item?.Owner?.name,
                      )}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          {/* Payout Amount Slider */}
          <div className="pb-6">
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px]  font-medium">
                {isCompany ? "Commission:" : "  Payout Amount"}
              </Text>
            </div>
            <div>
              <Slider
                value={
                  tempCheckboxValues?.commission?.length
                    ? tempCheckboxValues?.commission
                    : []
                }
                onChange={(value) => handleCheckboxChange(`commission`, value)}
              />
            </div>
            {isCompany && (
              <>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px]  font-medium">
                    Revenue:
                  </Text>
                </div>
                <Slider
                  value={
                    tempCheckboxValues?.revenue?.length
                      ? tempCheckboxValues?.revenue
                      : []
                  }
                  onChange={(value) => handleCheckboxChange(`revenue`, value)}
                />
              </>
            )}
          </div>

          {/* Apply and Cancel Buttons */}
          <div className="flex justify-end items-center gap-4 py-4 border-t border-[#EAECF0] fixed w-[362px] z-[1200] right-4 bottom-0 mt-7">
            <div className="px-4 flex gap-4">
              <Button
                onClick={() => {
                  setIsViewOpen(false);
                  onReset();
                }}
                className="w-[100px] h-[44px] bg-[#fff] border border-[#D0D5DD] rounded-[8px] font-semibold text-[14px] text-[#344054]"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={isLoading}
                onClick={() => setCheckboxValues(tempCheckboxValues)}
                className="w-[90px] h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {isLoading ? <Spin /> : "Apply"}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarFilter;
