import React from "react";
import { Checkbox } from "antd";

const App: React.FC<{
  data: {
    Name: string;
    Value: string;
  };
  checked?: boolean;
  key?: any;
  onChange: (Name: string, checked: boolean) => void;
}> = ({ data, onChange, checked, key }) => {
  return (
    <Checkbox
      key={key}
      onChange={(e) => onChange(data?.Value, e.target.checked)}
      checked={checked}
    >
      <span
        style={{
          backgroundColor: data?.bgColor,
          color: data?.color,
          ...(data.bgColor && {
            padding: "5px",
            borderRadius: "12px",
          }),
        }}
        className="text-[#344054] text-[14px] font-medium capitalize"
      >
        {data?.Name ? data?.Name : data?.name ? data?.name : "No user coming"}
      </span>
    </Checkbox>
  );
};

export default App;
