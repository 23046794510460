// //@ts-ignore
import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import Profile from "./profile.tsx";
import PasswordSection from "./password-section.tsx";
import Tabs from "./tabs.tsx";
import TableSection from "./table-section.tsx";
import ErrorLogsTable from "./error-logs-table.tsx";
import RemoveModal from "./remove-modal.tsx";
import EditModal from "./edit-modal.tsx";
import PipedriveSection from "./pipedrive-section.tsx";
import axiosClient from "../../utils/axios.ts";

const { Text } = Typography;

interface ErrorLog {
  id: number;
  module: string;
  context: string;
  message: string;
  timestamp: string;
}

const Main: React.FC = () => {
  const [itemIndex, setItemIndex] = useState(0);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [commissions, setCommissions] = useState([]);
  const [errorLogs, setErrorLogs] = useState<ErrorLog[]>([]);
  const [commissionId, setCommissionId] = useState<string | undefined>();
  const [commissionData, setCommissionData] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [id, setId] = useState("");

  const getAllCommissionModels = async () => {
    try {
      const res = await axiosClient().get("commission-model");
      setCommissions(res?.data);
    } catch (err) {
      console.error("Error fetching commission models:", err);
    }
  };

  const getAllErrorLogs = async () => {
    try {
      const res = await axiosClient().get("error-logs");
      setErrorLogs(res?.data);
    } catch (err) {
      console.error("Error fetching error logs:", err);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const res = await axiosClient().get("pipedrive-key");
        if (res?.data?.length > 0) {
          setApiKey(res.data[0].key);
          setId(res.data[0].pipedrive_key_id);
        }
      } catch (err) {
        console.error("Error fetching Pipedrive key:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
    getAllCommissionModels();
    getAllErrorLogs();
  }, []);

  const renderContent = () => {
    switch (itemIndex) {
      case 0:
        return <Profile />;
      case 1:
        return <PasswordSection />;
      case 2:
        return (
          <TableSection
            commissions={commissions}
            refresh={getAllCommissionModels}
            setIsRemoveOpen={setIsRemoveOpen}
            setIsEditOpen={setIsEditOpen}
            setCommissionId={setCommissionId}
            setCommissionData={setCommissionData}
          />
        );
      case 3:
        return <ErrorLogsTable errorLogs={errorLogs || []} />;
      default:
        return null;
    }
  };

  return (
    <div className="pb-20" style={{ paddingTop: "70px" }}>
      <Tabs setItemIndex={setItemIndex} itemIndex={itemIndex} />

      <div className="container py-12">
        <Row>
          <Col span={6}>
            <div className="grid">
              <Text className="text-[#101828] text-[18px] font-semibold font-sans">
                {
                  ["Profile", "Password", "Commission Models", "Error Logs"][
                    itemIndex
                  ]
                }
              </Text>
              <Text className="text-[#475467] text-[14px]">
                {
                  [
                    "Update your photo and personal details here.",
                    "Change your password by filling the details",
                    "Update your affiliate settings",
                    "Get all error logs",
                  ][itemIndex]
                }
              </Text>
            </div>
          </Col>
          <Col span={18}>
            <div
              className={`w-full p-5 bg-white rounded-[12px] shadow-md ${itemIndex === 2 || itemIndex === 3 ? "tabelSetting" : ""}`}
            >
              {renderContent()}
            </div>
          </Col>
        </Row>
      </div>

      <RemoveModal
        isRemoveOpen={isRemoveOpen}
        setIsRemoveOpen={setIsRemoveOpen}
        commissionData={commissionData}
        commissionId={commissionId}
        setCommissionData={setCommissionData}
        refresh={getAllCommissionModels}
      />
      <EditModal
        setIsEditOpen={setIsEditOpen}
        isEditOpen={isEditOpen}
        commissionData={commissionData}
        setCommissionData={setCommissionData}
        refresh={getAllCommissionModels}
      />
    </div>
  );
};

export default Main;
