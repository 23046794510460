import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function TeamSelectDropdown({
  options,
  title,
  value,
  onChange,
}) {
  return (
    <>
      <label>{title}</label>
      <Autocomplete
        className="dropBoxSelect"
        disablePortal
        options={options}
        value={value}
        disableClearable
        onChange={(event, newValue) => {
          onChange(newValue.value);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
}
