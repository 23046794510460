// @ts-nocheck
import React from "react";
import { Col, Row, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28">
          <div>
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(2).svg?updatedAt=1709125106913"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
                fontFamily: "Inter",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Password reset
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2" style={{ color: "#475467" }}>
                Your password has been successfully reset. <br /> Click below to
                log in.
              </Text>
            </div>
            <Button
              onClick={() => navigate("/")}
              type="primary"
              icon=""
              className="w-full h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-8"
            >
              Continue
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
