// @ts-nocheck
"use client";
import React, { useState, useEffect } from "react";
import { Heading } from "../../components/index.ts";
import SearchSection from "./search-section.tsx";
import Sidebar from "../../layout/sidebar/view.tsx";
import SidebarFilter from "../../layout/sidebar/filter.tsx";
import { COMPANIES_DATA_COLUMN, COMPANIES_DATA } from "../../constants/data.ts";
import Table from "./table.tsx";
import useOpenOrganization from "../../hooks/use-organization.tsx";
import { RootState } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";

const Main = () => {
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedObject, setSelectedObject] = useState();
  const [payoutData, setPayoutData] = useState();
  const [sidebarData, setSidebarData] = useState();
  const [viewData, setViewData] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dateShow, setDateShow] = useState(false);
  const [dateRange, setDateRange] = useState<{
    from: string;
    to: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const organization = useSelector(
    (state: RootState) => state.organization.organization,
  );
  const loading = useSelector((state: RootState) => state.isLoading.isLoading);
  const { getOrganizations } = useOpenOrganization();

  const applyFiltersAndDateRange = async () => {
    setIsLoading(true);
    try {
      // Prepare filter parameters
      const names = checkboxValues.company || [];
      const status = checkboxValues?.status || [];
      const affiliate = checkboxValues?.referred || [];
      const owner = checkboxValues?.closed || [];
      const commission = checkboxValues?.commission || [];
      const revenue = checkboxValues?.revenue || [];

      const queryParams = [];

      // Add filter parameters
      if (names.length) {
        const namesJson = JSON.stringify(names);
        queryParams.push(`Name=${encodeURIComponent(namesJson)}`);
      }
      if (status.length) {
        const statusJson = JSON.stringify(status);
        queryParams.push(`Status=${encodeURIComponent(statusJson)}`);
      }
      if (affiliate.length) {
        const affiliateJson = JSON.stringify(affiliate);
        queryParams.push(`Affiliate=${encodeURIComponent(affiliateJson)}`);
      }
      if (owner.length) {
        const ownerJson = JSON.stringify(owner);
        queryParams.push(`Owner=${encodeURIComponent(ownerJson)}`);
      }
      if (commission.length) {
        const commissionJson = JSON.stringify(commission);
        queryParams.push(`Commission=${encodeURIComponent(commissionJson)}`);
      }
      if (revenue.length) {
        const revenueJson = JSON.stringify(revenue);
        queryParams.push(`Revenue=${encodeURIComponent(revenueJson)}`);
      }

      // Add date range parameters
      if (dateRange?.from && dateRange?.to) {
        queryParams.push(`StartDate=${dateRange.from}`);
        queryParams.push(`EndDate=${dateRange.to}`);
      }

      const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

      const response = await axiosClient().get(
        `/pipedrive-organisations/all-referred/${queryString}`,
      );

      setPayoutData(response?.data);
      setIsFilterOpen(false);
      setIsCheck(true);
    } catch (error) {
      console.error("Error applying filters:", error);
      toast.error("Something went wrong!");
      setIsCheck(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to handle both date range and filter changes
  useEffect(() => {
    const hasFilters = Object.values(checkboxValues).some(
      (values) => Array.isArray(values) && values.length > 0,
    );

    if (hasFilters || dateRange) {
      applyFiltersAndDateRange();
    } else {
      // If no filters and no date range, get all organizations
      getOrganizations();
    }
  }, [dateRange, checkboxValues]);

  useEffect(() => {
    setPayoutData(organization);
    setSidebarData(organization);
  }, [organization]);

  const handleFilterReset = () => {
    setCheckboxValues({});
    setSelectedFilters({});
    setDateRange(null);
    getOrganizations();
  };

  return (
    <>
      <div
        className="container pt-10 text-[30px]"
        style={{ paddingTop: "100px" }}
      >
        <Heading heading="Companies" subHeading="List of referred companies" />

        <SearchSection
          setIsFilterOpen={setIsFilterOpen}
          setPayoutData={setPayoutData}
          payoutData={organization}
          data={organization}
          setDateShow={setDateShow}
          dateShow={dateShow}
          checkboxValues={checkboxValues}
          setCheckboxValues={setCheckboxValues}
          isCheck={isCheck}
          setIsCheck={setIsCheck}
          setIsSubmit={setIsSubmit}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />

        {loading ? (
          <div className="flex items-center justify-center my-10">
            <Spin fullscreen size="large" />
          </div>
        ) : (
          <>
            <div className="my-8">
              <Table
                setIsViewOpen={setIsViewOpen}
                activeTab={activeTab}
                setIsEditOpen={setIsEditOpen}
                column={COMPANIES_DATA_COLUMN}
                data={payoutData || organization}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
                setPayoutData={setPayoutData}
                payoutData={payoutData}
                setSelectedObject={setSelectedObject}
                selectedObject={selectedObject}
                setViewData={setViewData}
              />
            </div>
            <div>
              <Sidebar
                isViewOpen={isViewOpen}
                setIsViewOpen={setIsViewOpen}
                isCompany
                data={viewData}
                setViewData={setViewData}
              />
              <SidebarFilter
                isViewOpen={isFilterOpen}
                setIsViewOpen={setIsFilterOpen}
                sidebarData={sidebarData}
                isCompany
                organization={organization}
                setCheckboxValues={setCheckboxValues}
                checkboxValues={checkboxValues}
                setIsCheck={setIsCheck}
                isLoading={isLoading}
                onReset={handleFilterReset}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Main;
