// @ts-nocheck

import { createSlice } from "@reduxjs/toolkit";

interface Teams {
  admin: any[];
  employee: any[];
  affilliate: any[];
  superPartners: any[];
  miniPartners: any[];
}

const initialState: Teams = {
  admin: [],
  affilliate: [],
  employee: [],
  superPartners: [],
  miniPartners: [],
};

export const counterSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    getAdmins: (state, action) => {
      state.admin = action.payload;
    },
    addAffilliates: (state, action) => {
      state.affilliate = action.payload;
    },
    addEmployees: (state, action) => {
      state.employee = action.payload;
    },
    addSuperPartners: (state, action) => {
      state.superPartners = action.payload;
    },
    addMiniPartners: (state, action) => {
      state.miniPartners = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAdmins,
  addAffilliates,
  addEmployees,
  addSuperPartners,
  addMiniPartners,
} = counterSlice.actions;

export default counterSlice.reducer;
