// @ts-nocheck
import React from "react";
import { Button } from "antd";
import { SETTINGS_TABS } from "../../constants/data.ts";
const Tabs = ({ itemIndex, setItemIndex }: any) => {
  return (
    <div className=" border-b bg-white">
      <div className="container flex items-center justify-between h-[65px]">
        <div className="flex items-center gap-3">
          {SETTINGS_TABS?.map((item: any, index: number) => (
            <Button
              onClick={() => setItemIndex(index)}
              className={` h-[44px] shadow-none border-none p-[8px, 12px, 8px, 12px] rounded-[6px] font-semibold text-[16px] text-[#101828] flex items-center gap-2  ${
                itemIndex === index && "bg-[#EFF4FF]"
              }`}
              key={index}
            >
              {item?.label}
              {item?.isCount && (
                <div className="rounded-[16px] bg-[#F2F4F7] px-2 ">
                  {item?.counts}
                </div>
              )}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
