import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";

import { Spin, Button } from "antd"; // Import Ant Design Table
import TableApp from "./payoutTable.tsx";
import ArchivedFilterComponent from "./filters/index.tsx";

const Archived = ({ data, refresh, updateFilters }) => {
  const loading = useSelector((state: RootState) => state.isLoading.isLoading);

  useEffect(() => {
    return updateFilters({});
  }, []);

  return (
    <>
      <div>
        <h1 className="eventHeading">
          {data?.length ? data?.length : 0} Events
        </h1>
        <ArchivedFilterComponent updateFilters={updateFilters} />

        {loading ? (
          <div className="flex items-center justify-center my-10">
            <Spin fullscreen size="large" />
          </div>
        ) : (
          <div className="">
            <TableApp
              data={data}
              refresh={refresh}
              updateFilters={updateFilters}
            />
            <div style={{ textAlign: "end", margin: "18px 0" }}>
              <Button className="buttonDelete">Delete</Button>
              <Button className="buttonArchive">Unarchive</Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Archived;
