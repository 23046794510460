import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Header from "../../layout/header/index.tsx";
import EmployeeApproval from "./component/employee-approval/index.tsx";
import AffiliateApproval from "./component/affiliate-approval/index.tsx";
import Payouts from "../payouts/main.tsx";
import axiosClient from "../../utils/axios.ts";
import MiniPartnerApproval from "./component/mini-partner/index.tsx";
import SuperPartnerApproval from "./component/super-partner/index.tsx";
import Archived from "./component/archived/main.tsx";
import Reviewed from "./component/reviewed/main.tsx";

const Commision = () => {
  const [activeTab, setActiveTab] = useState("employee");
  const [employeeEvents, setEmployeeEvents] = useState([]);
  const [affiliateEvents, setAffiliateEvents] = useState([]);
  const [miniPartnerEvents, setMiniPartnerEvents] = useState([]);
  const [superPartnerEvents, setSuperPartnerEvents] = useState([]);
  const [reviewedEvents, setReviewedEvents] = useState([]);
  const [archivedEvents, setArchivedEvents] = useState([]);

  const [reviewedFilters, setReviewedFilters] = useState({});
  const [archivedFilters, setArchivedFilters] = useState({});

  const getReviewedEvents = async (filters = {}) => {
    const queryParams = new URLSearchParams(filters).toString();
    //Approved Transactions
    axiosClient()
      .get(`users-commissions/reviewed-formatted?${queryParams}`)
      .then((res) => {
        setReviewedEvents(res.data);
      })
      .catch((err) => console.error(err));
  };

  const updateArchivedFilters = (newFilters) => {
    setArchivedFilters(newFilters);
  };

  const updateReviewedFilters = (newFilters) => {
    setReviewedFilters(newFilters);
  };

  //Archived Transactions
  const getArchivedEvents = async (filters = {}) => {
    const queryParams = new URLSearchParams(filters).toString();
    axiosClient()
      .get(`transactions/archived-formatted?${queryParams}`)
      .then((res) => {
        setArchivedEvents(res.data.transactions);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getReviewedEvents();
    getArchivedEvents();
  }, []);

  useEffect(() => {
    if (activeTab === "archive") {
      getArchivedEvents(archivedFilters);
    }
  }, [activeTab, archivedFilters]);

  useEffect(() => {
    if (activeTab === "reviewed") {
      getReviewedEvents(reviewedFilters);
    }
  }, [activeTab, reviewedFilters]);

  const getPartnerEvents = async () => {
    axiosClient()
      .get("transactions/pending/affiliates")
      .then((res) => {
        setAffiliateEvents(res.data);
      })
      .catch((error) => console.error(error.message));
  };

  useEffect(() => {
    getPartnerEvents();
  }, []);

  const getEmployeeEvents = async () => {
    axiosClient()
      .get("transactions/pending/employees")
      .then((res) => {
        setEmployeeEvents(res.data);
      })
      .catch((error) => console.error(error.message));
  };

  useEffect(() => {
    getEmployeeEvents();
  }, []);

  const getMiniPartnerEvents = async () => {
    axiosClient()
      .get("transactions/pending/mini-partners")
      .then((res) => {
        setMiniPartnerEvents(res.data);
      })
      .catch((error) => console.error(error.message));
  };

  useEffect(() => {
    getMiniPartnerEvents();
  }, []);

  const getSuperPartnerEvents = async () => {
    axiosClient()
      .get("transactions/pending/super-partners")
      .then((res) => {
        setSuperPartnerEvents(res.data);
      })
      .catch((error) => console.error(error.message));
  };

  useEffect(() => {
    getSuperPartnerEvents();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "employee":
        return (
          <Box>
            <EmployeeApproval
              data={employeeEvents || []}
              refresh={getEmployeeEvents}
            />
          </Box>
        );
      case "affiliate":
        return (
          <Box>
            <AffiliateApproval
              data={affiliateEvents || []}
              refresh={getPartnerEvents}
            />
          </Box>
        );
      case "partner":
        return (
          <Box>
            <MiniPartnerApproval
              data={miniPartnerEvents || []}
              refresh={getMiniPartnerEvents}
            />
          </Box>
        );
      case "superpartner":
        return (
          <Box>
            <SuperPartnerApproval
              data={superPartnerEvents || []}
              refresh={getSuperPartnerEvents}
            />
          </Box>
        );
      case "archive":
        return (
          <Box>
            <Archived
              data={archivedEvents || []}
              refresh={() => {
                getArchivedEvents(archivedFilters);
                getEmployeeEvents();
                getPartnerEvents();
                getMiniPartnerEvents();
                getSuperPartnerEvents();
              }}
              updateFilters={updateArchivedFilters}
            />
          </Box>
        );
      case "reviewed":
        return (
          <Box>
            <Reviewed
              data={reviewedEvents || []}
              refresh={getReviewedEvents}
              updateFilters={updateReviewedFilters}
            />
          </Box>
        );

      case "payments":
        return <Payouts />;
      default:
        return null;
    }
  };

  return (
    <>
      <Header />

      <div className="container head">
        <Box sx={{ margin: "32px 0" }}>
          <h3
            style={{
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            Commission Management
          </h3>
        </Box>
        <Box className="tabs" sx={{ display: "flex", cursor: "pointer" }}>
          <span
            onClick={() => setActiveTab("employee")}
            style={
              activeTab === "employee"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Employee Approvals
          </span>
          <span
            onClick={() => setActiveTab("affiliate")}
            style={
              activeTab === "affiliate"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Partner Approvals
          </span>
          <span
            onClick={() => setActiveTab("partner")}
            style={
              activeTab === "partner"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Mini Partner Approvals
          </span>
          <span
            onClick={() => setActiveTab("superpartner")}
            style={
              activeTab === "superpartner"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Super Partner Approvals
          </span>
          <span
            onClick={() => setActiveTab("reviewed")}
            style={
              activeTab === "reviewed"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Reviewed
          </span>
          <span
            onClick={() => setActiveTab("archive")}
            style={
              activeTab === "archive"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Archived
          </span>
        </Box>

        <Box sx={{ marginTop: "20px" }}>{renderContent()}</Box>
      </div>
    </>
  );
};

export default Commision;
